import { useContext, useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactDataGrid from 'react-data-grid'
import classnames from 'classnames'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import HttpAxios from '../../../commons/hooks/http'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import { EErrorMsg } from '../../../commons/statics/controls'
import TableActions from '../../../commons/components/table/TableActions'
import TablePaged from '../../../commons/components/table/TablePaged'
import { useUserMe } from '../../../commons/hooks/userme'
import { AppContext } from '../../../commons/context/App-context'
import { ESelectCustom, ESelectTheme } from '../../../commons/statics/controls'
import IconPdf from '../../../images/pdf.svg'
import IconExcel from '../../../images/excel.svg'
import { ITiendas } from '../../../commons/interfaces/user'
import environment from '../../../environments/environment'
import { Errors } from '../../../commons/constants/submodules'
import { IParameters } from '../../../commons/interfaces/items'
import { currencyFormat, currencyFormat3Dig } from '../../../commons/utils/utils'
import es from 'date-fns/locale/es'
import ApexChart from 'react-apexcharts'
import './view62.scss'

registerLocale('es', es)

const defaultOption = {
  value: 0,
  label: 'TODOS',
}
let params: IParameters = {}
let memoParams: IParameters = {}

function View62() {
  const { changeIsLoading, changeSnackBar, changeUrlBackTop, changePayload62, payload62 } = useContext(AppContext)
  const history = useHistory()
  const [noData, setNoData] = useState<boolean>(false)
  const compPDF = (data: any) => {
    return data?.row?.urlformatodescuento ? (
      <a href={data.row.urlformatodescuento} target="blank">
        <img className="View62Pdf" src={IconPdf} alt="PDF" />
      </a>
    ) : (
      <></>
    )
  }
  const compPercentage = (data: any) => {
    return parseInt(data) < 90 ? <span style={{ color: '#CC0000' }}>{data}</span> : <span>{data}</span>
  }
  const compResumen = (data: any) => {
    return data?.row?.urlformato ? <a target="blank">Ver resumen</a> : <></>
  }
  const headerColumns = [
    { key: 'tienda', name: 'TIENDA', width: '7%', minWidth: 50 },
    { key: 'tipocuadre', name: 'TIPO CUADRATURA', width: '7%', minWidth: 50 },
    { key: 'numerocuadratura', name: '# CUADRATURA', width: '7%', minWidth: 50 },
    {
      key: 'nrocaja',
      name: '# CAJA',
      width: '7%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'fecha',
      name: 'FECHA',
      width: '5%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'turno',
      name: 'TURNO',
      width: '5%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'dnicajero',
      name: 'DNI CAJERO',
      width: '7%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    { key: 'cajeronombre', name: 'NOMBRES y APELLIDOS', width: '10%', minWidth: 50 },
    {
      key: 'estadocuadre',
      name: 'ESTADO CUADRE',
      width: '10%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'diferencia',
      name: 'DIFERENCIA S/.',
      width: '7%',
      minWidth: 50,
      cellClass: () => {
        return 'colright'
      },
    },
    {
      key: 'diferenciadolares',
      name: 'DIFERENCIA $',
      width: '7%',
      minWidth: 50,
      cellClass: () => {
        return 'colright'
      },
    },
    {
      key: 'diferenciagifcard',
      name: 'DIFERENCIA GIFTCARD',
      width: '7%',
      minWidth: 50,
      cellClass: () => {
        return 'colright'
      },
    },
    { key: 'observaciones', name: 'OBSERVACIONES', width: '7%', minWidth: 50 },
    {
      key: 'urlformatodescuento',
      name: 'VER FORMATO',
      width: '7%',
      minWidth: 50,
      formatter: (props: any) => compPDF(props),
      cellClass: () => {
        return 'colcenter'
      },
    },
  ]
  const [msgError, setMsgError] = useState<string>('')
  const [useMe, setUseMe] = useUserMe()
  const [stores, setStores] = useState<Array<ITiendas> | null>(null)
  const [checklists, setChecklists] = useState([])
  const [checkcuadraturas, setCheckcuadraturas] = useState([])
  const [status, setStatus] = useState([])
  const [lists, setLists] = useState({ value: 21, label: 'Checklist Revisión Visual Tienda' })
  const [cuadraturas, setCuadraturas] = useState({ id: 0, descripcion: 'TODOS' })
  const [stateAttention, setStateAttention] = useState(defaultOption)
  const [store, setStore] = useState(defaultOption)
  const [rows, setRows] = useState<Array<any>>([])
  const [columns, setColumns] = useState(headerColumns)
  const [parameters, setParameters] = useState<IParameters>({})
  const [totalPerPage, setTotalPerPage] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [totalPages, setTotalPages] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const selectStyles = ESelectCustom
  const selectTheme = ESelectTheme
  const [endDate, setEndDate] = useState(new Date())
  const subsDays = dayjs(new Date()).subtract(7, 'day')
  const [startDate, setStartDate] = useState(subsDays.toDate())
  const [fullName, setFullName] = useState('')
  const btnSearchRef = useRef<any>(null)

  const getItems = (items: any, hasDefault?: boolean): any => {
    let temp: any = hasDefault ? [] : [defaultOption]

    items?.forEach((s: any) => {
      temp.push({
        value: s.id,
        label: s.descripcion,
      })
    })

    return temp
  }

  const getTypes = () => {
    HttpAxios.post('/checklists', {
      idmodulo: 22,
      todos: false,
      identidad: 0,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setChecklists(options)
        if (payload62) {
          const elem = options.filter((el: any) => el.value === payload62?.idcuadratura)
          if (elem) {
            onChangeChecklists(elem[0])
          }
          searchByBack()
        }
        changePayload62(null)
      })
      .catch((err) => {
        changePayload62(null)
      })
  }

  const getStatus = () => {
    HttpAxios.post('/entidad/estados', {
      id: 18,
      idestadopadre: 0,
      todos: true,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setStatus(options)
        if (payload62) {
          const elem = options.filter((el: any) => el.value === payload62?.idestado)
          if (elem) {
            onChangeStateAtention(elem[0])
          }
        }
        getTypes()
      })
      .catch((err) => {
        getTypes()
      })
  }

  const handleExport = () => {
    changeIsLoading(true)

    HttpAxios.post('/cuadratura/diferencias_listaweb_xls', parameters)
      .then((res: any) => {
        const { err, url, response } = res?.data || {}
        changeIsLoading(false)

        if (err) {
          changeSnackBar(EErrorMsg.generic)
        } else {
          handleDownload(url || response)
        }
      })
      .catch(() => {
        changeIsLoading(false)
        changeSnackBar(EErrorMsg.generic)
      })
  }

  const handleDownload = (urlFile: string = '') => {
    const link = document.createElement('a')
    link.href = urlFile
    link.setAttribute('download', `${urlFile.split('/')[urlFile.split('/').length - 1]}`)
    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      link?.parentNode?.removeChild(link)
    }, 100)
  }

  const search = (page: number = payload62?.page || 0, isPaginated?: boolean) => {
    if (!isPaginated) {
      const rangeStart = startDate ? dayjs(startDate).format(environment.search.formatDate) : ''
      const rangeEnd = endDate ? dayjs('' + endDate).format(environment.search.formatDate) : ''

      params = {
        idtienda: store.value || 0,
        fechai: rangeStart,
        fechaf: rangeEnd || rangeStart,
        idtipocuadratura: stateAttention?.value,
        cajero: fullName || '',
        limit: 20,
        page: 0,
      }
      memoParams = params
    }

    let paramsSearch = params
    paramsSearch.page = page
    changeIsLoading(true)
    setInitLoading(true)
    setMsgError('')
    setRows([])

    HttpAxios.post('/cuadratura/diferencias_listaweb', paramsSearch)
      .then((res: any) => {
        const data = res?.data || {}
        const response = data.response || []
        if (response.length) {
          const { nextpage = 2, page = 1, total_pages = 0, total_rows = 0 } = data
          setNoData(false)
          setColumns(headerColumns)
          setRows(response)
          setTotalPerPage(total_rows || response.length)
          setActivePage(page)
          setTotalPages(total_pages)
          setNextPage(nextpage)
          setParameters(memoParams)
          setMsgError('')
        } else {
          setNoData(true)
          setRows([])
        }

        changeIsLoading(false)
        setInitLoading(false)
      })
      .catch(() => {
        setMsgError(Errors.generic)
        changeIsLoading(false)
        setInitLoading(false)
      })
  }

  const handlePaged = (ind: number) => {
    search(ind, true)
  }

  const onChangeChecklists = (evt: any) => {
    setLists(evt)
  }

  const onChangeFullName = (evt: any) => {
    setFullName(evt.target.value)
  }

  const onChangeCheckcuadraturas = (evt: any) => {
    setCuadraturas(evt)
  }

  const onChangeStateAtention = (evt: any) => {
    setStateAttention(evt)
  }

  const onChangeStores = (evt: any) => {
    setStore(evt)
  }

  const onChangeRange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const initBackValues = (stores: Array<ITiendas> | null) => {
    if (payload62) {
      const { fechai, fechaf } = payload62
      const y: number = Number(fechai.substring(0, 4))
      const m: number = Number(fechai.substring(4, 6)) - 1
      const d: number = Number(fechai.substring(6, 8))
      const yEnd: number = Number(fechaf.substring(0, 4))
      const mEnd: number = Number(fechaf.substring(4, 6)) - 1
      const dEnd: number = Number(fechaf.substring(6, 8))

      setStartDate(new Date(y, m, d))
      setEndDate(new Date(yEnd, mEnd, dEnd))

      const elem: any = (stores || []).filter((el: any) => el.id === payload62?.idtienda)
      if (elem) {
        const el = getItems(elem)
        onChangeStores(el.length === 1 ? el[0] : el[1])
      }

      setFullName(payload62?.supervisor! || '')
    }
  }

  const searchByBack = () => {
    if (payload62 && lists && btnSearchRef) {
      btnSearchRef.current.click()
    }
  }

  useEffect(() => {
    const stores: Array<ITiendas> | null = useMe?.tiendas || null
    setStores(stores)
    initBackValues(stores)
    getStatus()
    !payload62 && search()
    changeUrlBackTop('/submodulo/62')
    changeIsLoading(true)
  }, [])

  return (
    <>
      <div className="View62Search">
        <div className="row-6">
          <div className="col">
            <label className="label">Tienda</label>
            <Select id="store" name="store" styles={selectStyles} theme={selectTheme} value={store} onChange={(evt) => onChangeStores(evt)} placeholder="Selecciona una tienda" options={getItems(stores)} />
          </div>
          <div className="col">
            <label className="label">Tipo Cuadratura</label>
            <Select id="cuadratura" name="cuadratura" styles={selectStyles} theme={selectTheme} value={stateAttention} onChange={(evt) => onChangeStateAtention(evt)} placeholder="Selecciona un estado" options={status} />
          </div>
          <div className="col">
            <label className="label">Rango de fechas</label>
            <DatePicker
              selected={startDate}
              onChange={onChangeRange}
              startDate={startDate}
              endDate={endDate}
              selectsRange={true}
              locale="es"
              className="input"
              dateFormat="dd/MM/yyyy"
              placeholderText="Selecione un rango de fecha"
              maxDate={new Date()}
            />
          </div>
          <div className="col">
            <label className="label">Nombre Cajero</label>
            <input className="input" id="cajero" name="cajero" placeholder="Cajero" value={fullName} onChange={(evt) => onChangeFullName(evt)} />
          </div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button type="button" onClick={() => search()} className="button buttonFull" ref={btnSearchRef}>
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div className="View62Body">
        {noData || msgError ? (
          !initLoading && (
            <div
              className={classnames({
                noData: noData,
                msgError: msgError,
              })}
            >
              {msgError ? msgError : 'No se encontró resultado en la búsqueda'}
            </div>
          )
        ) : (
          <>
            <div className="View62SearchActions">
              <div className="View62SearchActionsTotal">{totalPerPage > 0 && 'Total de registros: ' + totalPerPage}</div>
              <div className="separate"></div>
              {totalPerPage > 0 && (
                <button className="button buttonExcel" type="button" onClick={handleExport}>
                  <img src={IconExcel} alt="Excel" className="buttonIconLeft" />
                  Exportar
                </button>
              )}
            </div>
            <ReactDataGrid
              className="View62Table"
              columns={columns}
              rows={rows}
              onRowsChange={setRows}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
            />
            <TablePaged msgError={msgError} activePage={activePage} handlePaged={handlePaged} totalPages={totalPages} />
          </>
        )}
      </div>
    </>
  )
}

export default View62
