import { useState, useEffect } from 'react'
import { SubModules, ISubModules } from '../constants/submodules'

interface ISubmodulesProp {
  idSubmodule: number | string | null
}

const useSubmodules = (props?: ISubmodulesProp): ISubModules => {
  const [views, setViews] = useState(SubModules)

  useEffect(() => {
    const view: Array<ISubModules> = SubModules.filter((sm) => sm.idSubmodule === Number(props?.idSubmodule))
    setViews(view)
  }, [props?.idSubmodule])

  return {
    view: views[0]?.view || null,
    idSubmodule: props?.idSubmodule || null,
    breadcrumb: views[0]?.breadcrumb || '',
  }
}

export default useSubmodules
