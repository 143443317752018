import React from 'react'
import './table.scss'

interface ITable {
  totalPages: number
  msgError: string
  activePage: number
  handlePaged: (page: number) => void
}

const TablePaged: React.FC<ITable> = ({ totalPages = 1, msgError, activePage = 1, handlePaged }) => {
  const maxPagesToShow = 5 // Number of page numbers to display
  const startPage = Math.max(1, activePage - Math.floor(maxPagesToShow / 2))
  const endPage = Math.min(totalPages || 1, startPage + maxPagesToShow - 1)

  // Event handlers
  const handlePreviousPage = () => {
    if (activePage > 1) handlePaged(activePage - 1)
  }

  const handleNextPage = () => {
    if (activePage < (totalPages || 1)) handlePaged(activePage + 1)
  }

  return (
    <div className="TableNav">
      {msgError ? (
        <div className="Error">{msgError}</div>
      ) : (
        <ul className="PaginationList">
          {/* Previous Button */}
          <li className="Arrow" onClick={handlePreviousPage} style={{ pointerEvents: activePage === 1 ? 'none' : 'auto' }}>
            Anterior
          </li>
          {/* Page Numbers */}
          {startPage > 1 && (
            <>
              <li className={`PageNumber ${1 === activePage ? 'Active' : ''}`} onClick={() => handlePaged(1)}>
                1
              </li>
              {startPage > 2 && <li className="Dots">...</li>}
            </>
          )}
          {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((page) => (
            <li key={page} className={`PageNumber ${page === activePage ? 'Active' : ''}`} onClick={() => handlePaged(page)}>
              {page}
            </li>
          ))}
          {endPage < (totalPages || 1) && (
            <>
              {endPage < (totalPages || 1) - 1 && <li className="Dots">...</li>}
              <li className={`PageNumber ${totalPages === activePage ? 'Active' : ''}`} onClick={() => handlePaged(totalPages || 1)}>
                {totalPages}
              </li>
            </>
          )}
          {/* Next Button */}
          <li className="Arrow" onClick={handleNextPage} style={{ pointerEvents: activePage === (totalPages || 1) ? 'none' : 'auto' }}>
            Siguiente
          </li>
        </ul>
      )}
    </div>
  )
}

export default React.memo(TablePaged)
