import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import classnames from 'classnames'
import './breadcrumb.scss'

export interface IBreadcrumb {
  text?: string
  urlBack?: string
}

function Breadcrumb(props: IBreadcrumb) {
  const { text, urlBack } = props

  return (
    <div
      className={classnames({
        Breadcrumb: true,
        BreadcrumbIcon: !!urlBack,
      })}
    >
      {text}{' '}
      {urlBack && (
        <Link className="BreadcrumbBackLink" to={urlBack} data-tip="Volver">
          <svg viewBox="0 0 32 32">
            <g>
              <path d="M10.1,23a1,1,0,0,0,0-1.41L5.5,17H29.05a1,1,0,0,0,0-2H5.53l4.57-4.57A1,1,0,0,0,8.68,9L2.32,15.37a.9.9,0,0,0,0,1.27L8.68,23A1,1,0,0,0,10.1,23Z" />
            </g>
          </svg>
        </Link>
      )}
      <ReactTooltip />
    </div>
  )
}

export default Breadcrumb
