import { Dialog, Transition } from '@headlessui/react'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/App-context'
import ReactTooltip from 'react-tooltip'
import IconClose from '../../../images/close.svg'
import IconLeft from '../../../images/arrow-left.svg'
import IconRight from '../../../images/arrow-right.svg'
import './modalPhoto.scss'

function ModalPhoto() {
  const { showModalPhoto, dataModalPhoto, changeShowModalPhoto } = useContext(AppContext)
  const [photo, setPhoto] = useState<string>('')
  const [photos, setPhotos] = useState<Array<string>>([])
  const [index, setIndex] = useState<number>(0)

  const nextModalPhoto = () => {
    const ind = index + 1
    setPhoto('')
    setPhoto(photos[ind])
    setIndex(ind)
  }

  const prevModalPhoto = () => {
    const ind = index - 1
    setPhoto('')
    setPhoto(photos[ind])
    setIndex(ind)
  }

  useEffect(() => {
    setPhoto(dataModalPhoto.photo)
    setIndex(dataModalPhoto.initIndex)
    setPhotos(dataModalPhoto.photos)
  }, [dataModalPhoto])

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  return (
    <>
      {showModalPhoto && (
        <Transition show={showModalPhoto} enter="ModalEnter" enterFrom="ModalEnterFrom" enterTo="ModalEnterTo" leave="ModalLeave" leaveFrom="ModalLeaveFrom" leaveTo="ModalLeaveTo">
          <Dialog className="ModalPhoto" onClose={() => changeShowModalPhoto(false)}>
            <Dialog.Overlay className="ModalPhotoOverlay" />
            <div className="ModalPhotoContent bgLinear">
              <Dialog.Description className="ModalPhotoDerscription" as="div">
                <div className="ModalPhotoMain">
                  <img src={photo} alt="Foto" />
                </div>
              </Dialog.Description>
              <button className="ModalPhotoClose" onClick={() => changeShowModalPhoto(false)} data-tip="Cerrar" data-for="close">
                <img src={IconClose} alt="Close" />
              </button>
              {photos.length > 0 && index < photos.length - 1 && (
                <button className="ModalPhotoNext" onClick={() => nextModalPhoto()} data-tip="Siguiente" data-for="next">
                  <img src={IconRight} alt="Siguiente" />
                </button>
              )}
              {index > 0 && (
                <button className="ModalPhotoPrev" onClick={() => prevModalPhoto()} data-tip="Anterior" data-for="prev">
                  <img src={IconLeft} alt="Anterior" />
                </button>
              )}
            </div>
            <ReactTooltip id="close" />
            <ReactTooltip id="next" />
            <ReactTooltip id="prev" />
          </Dialog>
        </Transition>
      )}
    </>
  )
}

export default ModalPhoto
