import { useContext } from 'react'
import { AppContext } from '../../../commons/context/App-context'
import './loader.scss'

function Loader() {
  const { isLoading } = useContext(AppContext)

  return (
    <>
      {isLoading && (
        <div className="Loader">
          <div className="LoaderInner">
            <span className="spinner"></span>
          </div>
        </div>
      )}
    </>
  )
}

export default Loader
