import { Fragment, ReactElement, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { useUserMe } from '../../hooks/userme'
import IconArrowDown from '../../../images/arrow-down.svg'
import IconClose from '../../../images/close-gray.svg'
import IconUser from '../../../images/user.svg'
import './popover.scss'

interface IPopover {
  name: string
}

const Popover = (props: IPopover): JSX.Element => {
  const { name } = props
  const history = useHistory()
  const [userMe, setUserMe] = useUserMe()

  const handleOnCloseClick = () => {
    setUserMe.reset()
    history.push('/signin')
    window.location.reload()
  }

  useEffect(() => {}, [userMe])

  return (
    <div className="PopoverItemsMenu">
      <Menu as={Fragment}>
        {({ open }) => (
          <>
            <Menu.Button as={Fragment}>
              <span className="PopoverContentIcon">
                <span className="PopoverAvatar">
                  <img src={IconUser} alt="" />
                </span>
                <span className="PopoverIcon">
                  <img src={open ? IconClose : IconArrowDown} alt="" />
                </span>
              </span>
            </Menu.Button>
            <Transition enter="PopoverEnter" enterFrom="PopoverEnterFrom" enterTo="PopoverEnterTo" leave="PopoverLeave" leaveFrom="PopoverLeaveFrom" leaveTo="PopoverLeaveTo" as={Fragment}>
              <Menu.Items as={Fragment}>
                <ul className="PopoverUl">
                  <Menu.Item as="li" className="PopoverLiName">
                    {({ active }) => <span className="PopoverLiNameText">Hola {name}</span>}
                  </Menu.Item>
                  <Menu.Item as="li" className="PopoverLi">
                    {({ active }) => (
                      <span onClick={handleOnCloseClick} className={`${active && 'active'}`}>
                        <svg width="19" height="17" viewBox="0 0 19 17" fill="currentColor">
                          <path d="M11.4636 13.0579C11.4636 12.9697 11.4602 12.8967 11.4527 12.8285C11.4119 12.4534 11.1234 12.1658 10.7517 12.1293C10.3411 12.0891 9.99352 12.3095 9.88672 12.6781C9.84863 12.8096 9.84523 12.9413 9.84523 13.065L9.84421 13.7611C9.84387 14.1166 9.84387 14.1166 9.84387 14.419V15.1449H2.5445V2.30324H9.84387V2.96284C9.84387 3.45788 9.84387 3.45788 9.84421 4.06578L9.84455 4.60407C9.84455 4.68281 9.84557 4.77438 9.85611 4.86866C9.88264 5.10249 10.0102 5.31064 10.2061 5.43973C10.4027 5.56948 10.6466 5.60564 10.8752 5.53941C11.2711 5.42418 11.4639 5.13527 11.4639 4.65645C11.4643 3.393 11.4643 3.28892 11.4643 2.63304V1.64904C11.4643 1.10163 11.3187 0.887053 10.8149 0.692079L10.77 0.674507H1.59009L1.55098 0.687348C1.11391 0.83096 0.901665 1.15164 0.902345 1.66796C0.907107 5.40053 0.906767 9.19594 0.906427 12.8663V15.7916C0.906427 16.4556 1.21901 16.7648 1.89077 16.7648C2.72069 16.7648 5.1929 16.7648 6.02282 16.7648C7.51124 16.7648 8.99966 16.7648 10.4881 16.7645H10.501C10.5813 16.7648 10.6629 16.7645 10.749 16.7567C11.1034 16.7249 11.3976 16.4489 11.4483 16.1005C11.4626 16.0035 11.4636 15.9092 11.4636 15.8285L11.4639 15.1753C11.4643 14.4508 11.4643 14.4508 11.4639 13.7574L11.4636 13.0579Z" />
                          <path d="M18.3814 8.05989C17.4583 7.13739 16.5165 6.20273 15.6059 5.29848L14.9801 4.67706L14.9546 4.65138C14.9087 4.60542 14.857 4.55339 14.7961 4.50608C14.5889 4.34557 14.3274 4.29387 14.0777 4.36382C13.8301 4.43343 13.6359 4.61218 13.5451 4.85446C13.4604 5.08019 13.4325 5.42114 13.7784 5.76311C14.4318 6.40885 15.0944 7.06778 15.7352 7.70508L15.9814 7.95007H15.1464C12.7189 7.95007 10.8427 7.95007 8.20704 7.95074C8.11588 7.95074 7.99582 7.95344 7.87507 7.97473C7.45977 8.04806 7.18086 8.4201 7.22644 8.84046C7.27541 9.29225 7.60636 9.5619 8.11146 9.5619C10.4608 9.56224 12.2196 9.56224 14.2862 9.56224H15.9876C15.8039 9.74471 15.6199 9.92718 15.4362 10.11C14.8838 10.6584 14.3124 11.2258 13.7519 11.7854C13.5437 11.9932 13.4529 12.275 13.5087 12.5396C13.5624 12.7944 13.74 12.9981 13.9961 13.0981C14.0838 13.1326 14.1886 13.1586 14.3042 13.1586C14.4934 13.1586 14.7114 13.0887 14.9304 12.8697C15.4328 12.3686 15.9359 11.8685 16.4393 11.3684C17.0515 10.7598 17.6848 10.1306 18.3059 9.50986L18.3178 9.49702C18.8889 8.84249 18.5464 8.22445 18.3814 8.05989Z" />
                        </svg>
                        Cerrar sesión
                      </span>
                    )}
                  </Menu.Item>
                </ul>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}

export default Popover
