import { useContext, useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactDataGrid from 'react-data-grid'
import classnames from 'classnames'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import HttpAxios from '../../../commons/hooks/http'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import { EErrorMsg, ESelectCustom, ESelectTheme } from '../../../commons/statics/controls'
import TableActions from '../../../commons/components/table/TableActions'
import TablePaged from '../../../commons/components/table/TablePaged'
import { useUserMe } from '../../../commons/hooks/userme'
import { AppContext } from '../../../commons/context/App-context'
import IconExcel from '../../../images/excel.svg'
import { ITiendas } from '../../../commons/interfaces/user'
import { Errors } from '../../../commons/constants/submodules'
import { IParameters } from '../../../commons/interfaces/items'
import environment from '../../../environments/environment'
import es from 'date-fns/locale/es'
import './view67.scss'

registerLocale('es', es)

const defaultOption = {
  value: 0,
  label: 'TODOS',
}
let params: IParameters = {}
let memoParams: IParameters = {}

function View67() {
  const { changeIsLoading, changeSnackBar, changeUrlBackTop, changePayload67, payload67 } = useContext(AppContext)
  const history = useHistory()
  const [noData, setNoData] = useState<boolean>(false)
  const headerColumns = [
    { key: 'zona', name: 'ZONA', width: '7%', minWidth: 50 },
    { key: 'tienda', name: 'TIENDA', width: '12%', minWidth: 50 },
    { key: 'arrendador', name: 'ARRENDADOR', width: '10%', minWidth: 50 },
    { key: 'fechainaugura', name: 'FECHA INAUGURA', width: '7%', minWidth: 50 },
    {
      key: 'mtotales',
      name: 'M2 TOTALES',
      width: '7%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'mutiles',
      name: 'M2 ÚTILES',
      width: '7%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'fechainicio',
      name: 'FECHA INICIO',
      width: '7%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'fechafin',
      name: 'FECHA FIN',
      width: '7%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'rentaminima',
      name: 'RENTA MÍNIMA',
      width: '19%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'rentavariable',
      name: 'RENTA VARIABLE',
      width: '10%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'status',
      name: 'STATUS',
      width: '7%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
  ]
  const [msgError, setMsgError] = useState<string>('')
  const [useMe, setUseMe] = useUserMe()
  const [stores, setStores] = useState<Array<ITiendas> | null>(null)
  const [checklists, setChecklists] = useState([])
  const [status, setStatus] = useState([])
  const [lists, setLists] = useState({ value: 21, label: 'Checklist Revisión Visual Tienda' })
  const [zona, setZona] = useState({ value: 0, label: 'TODOS' })
  const [checkzonas, setCheckzonas] = useState([])
  const [jefe, setJefe] = useState({ value: 0, label: 'TODOS' })
  const [checkjefes, setCheckjefes] = useState([])
  const [stateAttention, setStateAttention] = useState(defaultOption)
  const [store, setStore] = useState(defaultOption)
  const [rows, setRows] = useState<Array<any>>([])
  const [columns, setColumns] = useState(headerColumns)
  const [parameters, setParameters] = useState<IParameters>({})
  const [totalPerPage, setTotalPerPage] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [totalPages, setTotalPages] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const selectStyles = ESelectCustom
  const selectTheme = ESelectTheme
  const [endDate, setEndDate] = useState(new Date())
  const subsDays = dayjs(new Date()).subtract(7, 'day')
  const [startDate, setStartDate] = useState(subsDays.toDate())
  const [fullName, setFullName] = useState('')
  const btnSearchRef = useRef<any>(null)
  const [rowSelected, setRowSelected] = useState(null)
  const [idxSelected, setIdxSelected] = useState(null)
  const [statusSelected, setStatusSelected] = useState([])

  const getItems = (items: any, hasDefault?: boolean): any => {
    let temp: any = hasDefault ? [] : [defaultOption]

    items?.forEach((s: any) => {
      temp.push({
        value: !isNaN(s.id) ? s.id : !isNaN(s.idsector) ? s.idsector : !isNaN(s.idjefezonal) ? s.idjefezonal : 0,
        label: s.descripcion || s.nombre,
      })
    })

    return temp
  }

  const getZonas = () => {
    HttpAxios.post('/zonas/listar', {
      todos: true,
    }).then((res: any) => {
      const items: Array<any> = res?.data?.response || []
      const options = getItems(items, true)
      setCheckzonas(options)
      if (payload67) {
        const elem = options.filter((el: any) => el.value === payload67?.idsector)
        if (elem) {
          onChangeCheckZona(elem[0])
        }
      }
    })
  }

  const getJefes = () => {
    HttpAxios.post('/zonas/jefes', {
      todos: true,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setCheckjefes(options)
        if (payload67) {
          const elem = options.filter((el: any) => el.value === payload67?.idjefezonal)
          if (elem) {
            onChangeCheckJefe(elem[0])
          }
        }
        getZonas()
      })
      .catch((err) => {
        getZonas()
      })
  }

  const handleExport = () => {
    changeIsLoading(true)

    HttpAxios.post('/contrato/exportar_lista_xls', parameters)
      .then((res: any) => {
        const { err, url, response } = res?.data || {}
        changeIsLoading(false)

        if (err) {
          changeSnackBar(EErrorMsg.generic)
        } else {
          handleDownload(url || response)
        }
      })
      .catch(() => {
        changeIsLoading(false)
        changeSnackBar(EErrorMsg.generic)
      })
  }

  const handleDownload = (urlFile: string = '') => {
    const link = document.createElement('a')
    link.href = urlFile
    link.setAttribute('download', `${urlFile.split('/')[urlFile.split('/').length - 1]}`)
    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      link?.parentNode?.removeChild(link)
    }, 100)
  }

  const search = (page: number = payload67?.page || 0, isPaginated?: boolean) => {
    // if (!isPaginated) {
    params = {
      idtienda: payload67?.idtienda || store?.value || 0,
      idzona: payload67?.idzona || zona?.value || 0,
      idjefezonal: payload67?.idjefezonal || jefe?.value || 0,
      todos: true,
      limit: environment.search.limitPerPage,
    }
    memoParams = params
    // }

    let paramsSearch = params
    paramsSearch.page = page
    memoParams.page = page
    changeIsLoading(true)
    setInitLoading(true)
    setMsgError('')
    setRows([])

    HttpAxios.post('/contrato/listar', paramsSearch)
      .then((res: any) => {
        const data = res?.data || {}
        const response = data.response || []
        if (response.length) {
          const { nextpage = 2, page = 1, total_pages = 0, total_rows = 0 } = data
          setNoData(false)
          setColumns(headerColumns)
          setRows(response)
          setTotalPerPage(total_rows || response.length)
          setActivePage(page)
          setTotalPages(total_pages)
          setNextPage(nextpage)
          setParameters(memoParams)
          setMsgError('')
        } else {
          setNoData(true)
          setRows([])
        }

        changeIsLoading(false)
        setInitLoading(false)
      })
      .catch(() => {
        setMsgError(Errors.generic)
        changeIsLoading(false)
        setInitLoading(false)
      })
  }

  const handlePaged = (ind: number) => {
    search(ind, true)
  }

  const onChangeCheckZona = (evt: any) => {
    setZona(evt)
  }

  const onChangeCheckJefe = (evt: any) => {
    setJefe(evt)
  }

  const onChangeStores = (evt: any) => {
    setStore(evt)
  }

  const initBackValues = (stores: Array<ITiendas> | null) => {
    if (payload67) {
      const elem: any = (stores || []).filter((el: any) => el.id === payload67?.idtienda)
      if (elem) {
        const el = getItems(elem)
        onChangeStores(el.length === 1 ? el[0] : el[1])
      }
    }
  }

  const rowClick = (obj: any, r: any): void => {
    changePayload67(memoParams)
    const url = '/detalle/submodulo/67/' + obj?.idcontrato
    history.push(url)
  }

  const newContrato = (evt: any) => {
    changePayload67(memoParams)
    const url = '/detalle/submodulo/67/0'
    history.push(url)
  }

  useEffect(() => {
    const stores: Array<ITiendas> | null = useMe?.tiendas || null
    setStores(stores)
    initBackValues(stores)
    getJefes()
    search()
    changeUrlBackTop('/submodulo/67')
    changeIsLoading(true)
  }, [])

  useEffect(() => {
    changePayload67({
      idtienda: store?.value || 0,
      idzona: zona?.value || 0,
      idjefezonal: jefe?.value || 0,
      todos: true,
      limit: environment.search.limitPerPage,
    })
  }, [store, zona, jefe])

  return (
    <>
      <div className="View67Search">
        <div className="row-6">
          <div className="col">
            <label className="label">Zona</label>
            <Select id="zonas" name="zonas" styles={selectStyles} theme={selectTheme} value={zona} onChange={(evt) => onChangeCheckZona(evt)} placeholder="Selecciona una zona" options={checkzonas} />
          </div>
          <div className="col">
            <label className="label">Jefe Zonal</label>
            <Select id="jefes" name="jefes" styles={selectStyles} theme={selectTheme} value={jefe} onChange={(evt) => onChangeCheckJefe(evt)} placeholder="Selecciona un jefe" options={checkjefes} />
          </div>
          <div className="col">
            <label className="label">Tienda</label>
            <Select id="store" name="store" styles={selectStyles} theme={selectTheme} value={store} onChange={(evt) => onChangeStores(evt)} placeholder="Selecciona una tienda" options={getItems(stores)} />
          </div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button type="button" onClick={() => search()} className="button buttonFull" ref={btnSearchRef}>
              Buscar
            </button>
          </div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button type="button" onClick={(evt) => newContrato(evt)} className="button buttonFull buttonNew" ref={btnSearchRef}>
              Nuevo
            </button>
          </div>
        </div>
      </div>

      <div className="View67Body">
        {noData || msgError ? (
          !initLoading && (
            <div
              className={classnames({
                noData: noData,
                msgError: msgError,
              })}
            >
              {msgError ? msgError : 'No se encontró resultado en la búsqueda'}
            </div>
          )
        ) : (
          <>
            <div className="View67SearchActions">
              <div className="View67SearchActionsTotal">{totalPerPage > 0 && 'Total de registros: ' + totalPerPage}</div>
              <div className="separate"></div>
              {totalPerPage > 0 && (
                <button className="button buttonExcel" type="button" onClick={handleExport}>
                  <img src={IconExcel} alt="Excel" className="buttonIconLeft" />
                  Exportar
                </button>
              )}
            </div>
            <ReactDataGrid
              className="View67Table"
              columns={columns}
              rows={rows}
              onRowsChange={setRows}
              onRowClick={rowClick}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
            />
            <TablePaged msgError={msgError} activePage={activePage} handlePaged={handlePaged} totalPages={totalPages} />
          </>
        )}
      </div>
    </>
  )
}

export default View67
