import { HashRouter as Router, Switch } from 'react-router-dom'
import { configure } from 'axios-hooks'
import { InterceptorConfig, InterceptorError } from './commons/interceptor/interceptor'
import { AppContextProvider } from './commons/context/App-context'
import { checkSepUpTime } from '../src/commons/utils/utils'
import Axios from 'axios'
import Dashboard from './bussines/dashboard/Dashboard'
import Submodule from './bussines/submodule/Submodule'
import ESign from './bussines/esign/index'
import Details from './bussines/details/Details'
import Login from './bussines/authentication/login/Login'
import ProtectedRoute from './commons/components/protected-route/Protected-route'
import UnProtectedRoute from './commons/components/protected-route/UnProtected-route'
import Loader from './commons/components/loader/Loader'
import Snackbar from './commons/components/snackbar/Snackbar'
import environment from './environments/environment'

import './App.scss'
import 'react-datepicker/dist/react-datepicker.css'

checkSepUpTime()
const axios = Axios.create({
  baseURL: environment.apiURL,
})
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.interceptors.request.use(InterceptorConfig, InterceptorError)
axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

configure({ axios })

function App() {
  return (
    <div className="App">
      <AppContextProvider>
        <Router>
          <Switch>
            <UnProtectedRoute path="/iniciar-sesion" component={Login}></UnProtectedRoute>
            <ProtectedRoute path="/firmaformatodscto" component={ESign}></ProtectedRoute>
            <ProtectedRoute path="/submodulo/:id" component={Submodule}></ProtectedRoute>
            <ProtectedRoute path="/detalle/submodulo/:id/:idcheckevento" component={Details}></ProtectedRoute>
            <ProtectedRoute path="/" component={Dashboard}></ProtectedRoute>
          </Switch>
        </Router>
        <Loader />
        <Snackbar />
      </AppContextProvider>
    </div>
  )
}

export default App
