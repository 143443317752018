import { useContext, useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { EErrorMsg, ESelectCustom, ESelectTheme } from '../../../commons/statics/controls'
import { useUserMe } from '../../../commons/hooks/userme'
import { AppContext } from '../../../commons/context/App-context'
import { ITiendas } from '../../../commons/interfaces/user'
import { Errors } from '../../../commons/constants/submodules'
import { IParameters } from '../../../commons/interfaces/items'
import { useLocalStorage } from "react-use";
import ReactDataGrid from 'react-data-grid'
import classnames from 'classnames'
import HttpAxios from '../../../commons/hooks/http'
import Select, { components } from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import TablePaged from '../../../commons/components/table/TablePaged'
import environment from '../../../environments/environment'
import IconExcel from '../../../images/excel.svg'
import IconPdf from '../../../images/pdf.svg'
import es from 'date-fns/locale/es'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import './view72.scss'

registerLocale('es', es)

const defaultOption = {
  value: 0,
  label: 'TODOS',
}

let params: IParameters = {}
let memoParams: IParameters = {}

function View72() {
  const { changeIsLoading, changeSnackBar, changeUrlBackTop, changePayload72, payload72 } = useContext(AppContext)
  const history = useHistory()
  const [noData, setNoData] = useState<boolean>(false)

  const PdfCellRenderer = (data: any) => {
    const urlFormat = data?.row?.urlformatodescuento
    if (!urlFormat) return null

    return (
      <a href={urlFormat} target="_blank" rel="noopener noreferrer" className="colcenter">
        <img className="View72Pdf" src={IconPdf} alt="PDF" />
      </a>
    )
  }
  const headerColumns = [
    {
      key: 'tienda',
      name: 'TIENDA',
      width: '10%',
      frozen: true,
      cellClass: () => {
        return 'colleft'
      },
    },
    {
      key: 'fecha',
      name: 'FECHA',
      width: '8%',
    },
    {
      key: 'numerocuadratura',
      name: 'NUM CUADRATURA',
      width: '15%',
      frozen: true,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'cajeronombre',
      name: 'CAJERO',
      width: '25%',
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'supervisor',
      name: 'SUPERVISOR',
      width: '12%',
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'caja',
      name: 'CAJA',
      width: '5%',
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'turno',
      name: 'TURNO',
      width: '5%',
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'totalventa',
      name: 'TOTAL VENTA',
      width: '10%',
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'totalcuadrado',
      name: 'TOTAL CUADRE',
      width: '12%',
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'diferenciacuadre',
      name: 'DIFERENCIA',
      width: '8%',
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'observacionsupervisor',
      name: 'OBSERVACIONES SUPERVISOR',
      width: '25%',
      cellClass: () => {
        return 'colleft'
      },
    },
    {
      key: 'urlformatodescuento',
      name: 'FORMATO DESCUENTO',
      width: '15%',
      cellClass: () => {
        return 'colcenter'
      },
      formatter: PdfCellRenderer,
    },
    {
      key: 'motivodiferencia',
      name: 'MOTIVO DIFERENCIA',
      width: '15%',
      cellClass: () => {
        return 'colleft'
      },
    },
    {
      key: 'estadovalidacion',
      name: 'ESTADO DE REVISIÓN',
      width: '15%',
      cellClass: () => {
        return 'colleft'
      },
    },
  ]

  const subsDays = dayjs(new Date()).subtract(7, 'day')
  const btnSearchRef = useRef<any>(null)

  const [msgError, setMsgError] = useState<string>('')
  const [useMe] = useUserMe()
  const [stores, setStores] = useState<Array<ITiendas> | null>(null)
  const [store, setStore] = useLocalStorage('store72', defaultOption)
  const [turnos, setTurnos] = useState<Array<ITiendas> | null>(null)
  const [turno, setTurno] = useLocalStorage('turno72', defaultOption)
  const [validationStatusList, setValidationStatusList] = useState<Array<ITiendas> | null>(null)
  const [validationStatus, setValidationStatus] = useLocalStorage('validationStatus72', defaultOption)
  const [rows, setRows] = useState<Array<any>>([])
  const [columns, setColumns] = useState(headerColumns)
  const [parameters, setParameters] = useState<IParameters>({})
  const [totalPerPage, setTotalPerPage] = useState(0)
  const [, setNextPage] = useState(2)
  const [totalPages, setTotalPages] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const [endDate, setEndDate] = useLocalStorage('endDate72', new Date())
  const [startDate, setStartDate] = useLocalStorage('startDate72', subsDays.toDate())
  const [fullName, setFullName] = useLocalStorage('fullName72', '')

  const getItems = (items: any, hasDefault?: boolean): any => {
    let temp: any = hasDefault ? [] : [defaultOption]

    items?.forEach((s: any) => {
      temp.push({
        value: !isNaN(s.id) ? s.id : !isNaN(s.idsector) ? s.idsector : !isNaN(s.idjefezonal) ? s.idjefezonal : 0,
        label: s.descripcion || s.nombre,
      })
    })

    return temp
  }

  const getTurnos = () => {
    HttpAxios.post('/entidad/estados', {
      id: 20,
      idestadopadre: 0,
      todos: false,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)

        setTurnos(items)
        if (payload72) {
          const elem = options.filter((el: any) => el.value === payload72?.idturno)
          if (elem) {
            onChangeCheckTurno(elem[0])
          }
          searchByBack()
        }
        changePayload72(null)
      })
      .catch(() => {
        changePayload72(null)
      })
  }

  const getEstados = () => {
    HttpAxios.post('/entidad/estados', {
      id: 41,
      idestadopadre: 0,
      todos: false,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)

        setValidationStatusList(items)
        if (payload72) {
          const elem = options.filter((el: any) => el.value === payload72?.idturno)
          if (elem) {
            onChangeCheckValidationStatus(elem[0])
          }
          searchByBack()
        }
        changePayload72(null)
      })
      .catch(() => {
        changePayload72(null)
      })
  }

  const handleExport = () => {
    changeIsLoading(true)

    HttpAxios.post('/cuadratura/listawebcontable_xls', parameters)
      .then((res: any) => {
        const { err, url, response } = res?.data || {}
        changeIsLoading(false)

        if (err) {
          changeSnackBar(EErrorMsg.generic)
        } else {
          handleDownload(url || response)
        }
      })
      .catch(() => {
        changeIsLoading(false)
        changeSnackBar(EErrorMsg.generic)
      })
  }

  const handleDownload = (urlFile: string = '') => {
    const link = document.createElement('a')
    link.href = urlFile
    link.setAttribute('download', `${urlFile.split('/')[urlFile.split('/').length - 1]}`)
    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      link?.parentNode?.removeChild(link)
    }, 100)
  }

  const search = (page: number = payload72?.page || 0, isPaginated?: boolean) => {
    if (!isPaginated) {
      const rangeStart = startDate ? dayjs(startDate).format(environment.search.formatDate) : ''
      const rangeEnd = endDate ? dayjs('' + endDate).format(environment.search.formatDate) : ''

      params = {
        idtienda: store?.value || 0,
        idturno: turno?.value || 0,
        idestadocuadre: validationStatus?.value || 0,
        supervisor: fullName,
        fecha_ini: rangeStart,
        fecha_fin: rangeEnd || rangeStart,
        limit: environment.search.limitPerPage,
      }
      memoParams = params
    }

    let paramsSearch = params
    paramsSearch.page = page
    memoParams.page = page
    changeIsLoading(true)
    setInitLoading(true)
    setMsgError('')
    setRows([])

    HttpAxios.post('/cuadratura/listawebcontable', paramsSearch)
      .then((res: any) => {
        const data = res?.data || {}
        const response = data.response || []
        if (response.length) {
          const { nextpage = 2, page = 1, total_pages = 0, total_rows = 0 } = data
          setNoData(false)
          setColumns(headerColumns)
          setRows(response)
          setTotalPerPage(total_rows || response.length)
          setActivePage(page)
          setTotalPages(total_pages)
          setNextPage(nextpage)
          setParameters(memoParams)
          setMsgError('')
        } else {
          setNoData(true)
          setRows([])
        }

        changeIsLoading(false)
        setInitLoading(false)
      })
      .catch(() => {
        setMsgError(Errors.generic)
        changeIsLoading(false)
        setInitLoading(false)
      })
  }

  const handlePaged = (ind: number) => {
    search(ind, true)
  }

  const onChangeFullName = (evt: any) => {
    setFullName(evt.target.value)
  }

  const onChangeCheckValidationStatus = (evt: any) => {
    setValidationStatus(evt)
  }

  const onChangeCheckTurno = (evt: any) => {
    setTurno(evt)
  }

  const onChangeStores = (evt: any) => {
    setStore(evt)
  }

  const onChangeRange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const initBackValues = (stores: Array<ITiendas> | null) => {
    if (payload72) {
      const elem: any = (stores || []).filter((el: any) => el.id === payload72?.idtienda)
      if (elem) {
        const el = getItems(elem)
        onChangeStores(el.length === 1 ? el[0] : el[1])
      }
    }
  }

  const searchByBack = () => {
    if (payload72 && turnos && validationStatus && btnSearchRef) {
      btnSearchRef.current.click()
    }
  }

  const rowClick = (obj: any, r: any): void => {
    if (r?.key !== 'urlformatodescuento') {
      changePayload72(memoParams)
      const url = '/detalle/submodulo/72/' + obj?.idcuadratura
      history.push(url)
    }
  }

  useEffect(() => {
    const stores: Array<ITiendas> | null = useMe?.tiendas || null
    setStores(stores)
    initBackValues(stores)
    getTurnos()
    getEstados()
    search()
    changeUrlBackTop('/submodulo/72')
    changeIsLoading(true)
  }, [])

  return (
    <>
      <div className="View72Search">
        <div className="row-6">
          <div className="col">
            <label className="label">Tienda</label>
            <Select
              id="store"
              name="store"
              styles={ESelectCustom}
              theme={ESelectTheme}
              value={store}
              isMulti={false}
              hideSelectedOptions={false}
              onChange={(evt) => onChangeStores(evt)}
              placeholder="Selecciona una tienda"
              options={getItems(stores)}
              components={{ ValueContainer }}
            />
          </div>
          <div className="col">
            <label className="label">Turno</label>
            <Select
              id="turno"
              name="turno"
              styles={ESelectCustom}
              theme={ESelectTheme}
              value={turno}
              isMulti={false}
              hideSelectedOptions={false}
              onChange={(evt) => onChangeCheckTurno(evt)}
              placeholder="Selecciona un turno"
              options={getItems(turnos)}
              components={{ ValueContainer }}
            />
          </div>
          <div className="col">
            <label className="label">Rango de fechas</label>
            <DatePicker
              onChange={onChangeRange}
              startDate={startDate ? new Date(startDate) : null}
              endDate={endDate ? new Date(endDate) : null}
              selectsRange={true}
              locale="es"
              className="input"
              dateFormat="dd/MM/yyyy"
              placeholderText="Selecione un rango de fecha"
              maxDate={new Date()}
            />
          </div>
          <div className="col">
            <label className="label">Estado Validación</label>
            <Select
              id="validationstatus"
              name="validationstatus"
              styles={ESelectCustom}
              theme={ESelectTheme}
              value={validationStatus}
              isMulti={false}
              hideSelectedOptions={false}
              onChange={(evt) => onChangeCheckValidationStatus(evt)}
              placeholder="Selecciona un turno"
              options={getItems(validationStatusList)}
              components={{ ValueContainer }}
            />
          </div>
          <div className="col">
            <label className="label">Supervisor</label>
            <input
              className="input"
              id="fullName"
              name="fullName"
              placeholder="Supervisor"
              value={fullName}
              onChange={(evt) => onChangeFullName(evt)}
            />
          </div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button type="button" onClick={() => search()} className="button buttonFull" ref={btnSearchRef}>
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div className="View72Body">
        {noData || msgError ? (
          !initLoading && (
            <div
              className={classnames({
                noData: noData,
                msgError: msgError,
              })}
            >
              {msgError ? msgError : 'No se encontró resultado en la búsqueda'}
            </div>
          )
        ) : (
          <>
            <div className="View72SearchActions">
              <div className="View72SearchActionsTotal">
                <div>{totalPerPage > 0 && 'Total de registros: ' + totalPerPage}</div>
              </div>
              <div className="separate"></div>
              {totalPerPage > 0 && (
                <button className="button buttonExcel" type="button" onClick={handleExport}>
                  <img src={IconExcel} alt="Excel" className="buttonIconLeft" />
                  Exportar
                </button>
              )}
            </div>

            {!initLoading && (
              <>
                <ReactDataGrid
                  className="View72Table"
                  columns={columns}
                  rows={rows}
                  onRowsChange={setRows}
                  onRowClick={rowClick}
                  defaultColumnOptions={{
                    sortable: true,
                    resizable: true,
                  }}
                />
                <TablePaged msgError={msgError} activePage={activePage} handlePaged={handlePaged} totalPages={totalPages} />
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

const ValueContainer = ({ children, ...props }: any) => {
  let [values, input] = children
  if (Array.isArray(values)) {
    const val = (i: any) => values[i].props.children
    const { length } = values
    switch (length) {
      case 1:
        values = `${val(0)} `
        break
      default:
        const otherCount = length - 1
        values = `${val(0)}+ ${otherCount} `
        break
    }
  }
  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  )
}

export default View72
