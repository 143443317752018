import environment from '../../../environments/environment'
import './footer.scss'

function Footer() {
  return <div className="Footer">
    CasaIdeas - Todos los derechos reservados (R). Versión {environment.version}
  </div>
}

export default Footer
