import axios from 'axios'
import environment from '../../environments/environment'
import { InterceptorConfig, InterceptorError } from '../interceptor/interceptor'

const HttpAxios = axios.create({
  baseURL: environment.apiURL,
})

HttpAxios.defaults.headers.common['Accept'] = 'application/json'
HttpAxios.defaults.headers.common['Content-Type'] = 'application/json'
HttpAxios.interceptors.request.use(InterceptorConfig, InterceptorError)
HttpAxios.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config
    if (originalConfig.url !== '/api/login' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          const user = JSON.parse(localStorage.getItem('userme') || '{}')

          await HttpAxios.post('/api/refresh_token', {
            username: 'jcnevado',
            refreshToken: user.refresh_token,
          })

          return HttpAxios(originalConfig)
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
    }
    return Promise.reject(err)
  }
)

export default HttpAxios
