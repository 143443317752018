import { Dialog, Transition } from '@headlessui/react'
import { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { ESelectCustom, ESelectTheme } from '../../../commons/statics/controls'
import { AppContext } from '../../context/App-context'
import HttpAxios from '../../../commons/hooks/http'
import ReactTooltip from 'react-tooltip'
import { EErrorMsg, ESuccessMsg } from '../../../commons/statics/controls'
import useGeo from '../../../commons/hooks/geo'
import IconClose from '../../../images/close.svg'
import './modalEdit55.scss'

const defaultOption = {
  value: 0,
  label: 'Seleccione una opción',
}

function ModalEdit55(props: any) {
  const selectStyles = ESelectCustom
  const selectTheme = ESelectTheme
  const { changeIsLoading, showModalEdit55, changeShowModalEdit55, changeSnackBar } = useContext(AppContext)
  const [stateAttention, setStateAttention] = useState(defaultOption)
  const [isDisabledSave, setIsDisabledSave] = useState<boolean>(true)
  const [status, setStatus] = useState([])
  const [obs, setObs] = useState<string>('')
  const [msgError, setMsgError] = useState<string>('')
  const { latitude, altitude, longitude } = useGeo()

  const onChangeStateAtention = (evt: any) => {
    setStateAttention(evt)
    setIsDisabledSave(false)
  }

  const getItems = (items: any, hasDefault?: boolean): any => {
    let temp: any = hasDefault ? [] : []

    items?.forEach((s: any) => {
      temp.push({
        value: s.id,
        label: s.descripcion,
      })
    })

    return temp
  }

  const onChangeObs = (evt: any) => {
    setMsgError('')
    setObs(evt.target.value)
    setIsDisabledSave(false)
  }

  const getData = (idcheckevento: number | string) => {
    changeIsLoading(true)

    HttpAxios.get('/incidencias/detalle_byid/' + idcheckevento)
      .then((res: any) => {
        const data = res?.data?.response || []
        if (data) {
          const newData = data[0] || {}
          setObs(newData.observacion || '')
          setStateAttention({
            label: props.row.estadoatencion,
            value: newData.idestado,
          })
        }
        changeIsLoading(false)
      })
      .catch(() => {
        changeIsLoading(false)
      })
  }

  const save = () => {
    changeIsLoading(true)
    setMsgError('')

    HttpAxios.post('/incidencias/actualizar_atencion', {
      idincidenciadetalle: props.row.idincidenciadetalle,
      idestado: stateAttention ? stateAttention.value || 0 : 0,
      observacion: obs,
      coordenadas: '' + (latitude || '') + ',' + (altitude || longitude || '') + '',
    })
      .then((res: any) => {
        const data = res?.data || {}
        const response = data || []
        if (response.err) {
          setMsgError(response.err)
        } else {
          props.onSuccess({
            stateAttention: stateAttention,
            obs: obs,
            row: props.row,
            status: props.status,
            idx: props.idxSelected,
          })
          changeSnackBar(ESuccessMsg.saveObs)
          setMsgError('')
        }

        changeIsLoading(false)
        changeShowModalEdit55(false)
      })
      .catch(() => {
        setMsgError(EErrorMsg.generic)
        changeIsLoading(false)
      })
  }

  const checkStatus = () => {
    const sts = props.status
    const status = sts.splice(1, 10)

    if (status) {
      setStatus(status)
    }
  }

  const checkRow = () => {
    const row = props.row
    if (row) {
      getData(row.idincidenciadetalle)
    }
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    setMsgError('')
  }, [])

  useEffect(() => {
    checkStatus()
  }, [props.status])

  useEffect(() => {
    checkRow()
  }, [props.row])

  return (
    <>
      {showModalEdit55 && (
        <Transition show={showModalEdit55} enter="ModalEnter" enterFrom="ModalEnterFrom" enterTo="ModalEnterTo" leave="ModalLeave" leaveFrom="ModalLeaveFrom" leaveTo="ModalLeaveTo">
          <Dialog className="ModalEdit55" onClose={() => changeShowModalEdit55(false)}>
            <Dialog.Overlay className="ModalEdit55Overlay" />
            <div className="ModalEdit55Content bgLinear">
              <Dialog.Description className="ModalEdit55Derscription" as="div">
                <div className="ModalEdit55Main">
                  <h2>Editar atención de incidencia</h2>
                  <div className="row-2">
                    <div className="col">
                      <label className="label">Estado de Atención</label>
                      <Select
                        id="stateAttention"
                        name="stateAttention"
                        styles={selectStyles}
                        theme={selectTheme}
                        value={stateAttention}
                        onChange={(evt: any) => onChangeStateAtention(evt)}
                        placeholder="Selecciona un estado"
                        options={status}
                      />
                    </div>
                    <div className="col">&nbsp;</div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className="label">Observaciones</label>
                      <textarea className="textarea" placeholder="Ingresa las Observaciones" value={obs} onChange={(evt) => onChangeObs(evt)}></textarea>
                    </div>
                  </div>
                  <div className="row actions">
                    <button type="button" onClick={() => save()} className="button" disabled={isDisabledSave}>
                      Actualizar Estado
                    </button>
                  </div>
                  {msgError && <div className="row error msgError">{msgError}</div>}
                </div>
              </Dialog.Description>
              <button className="ModalEdit55Close" onClick={() => changeShowModalEdit55(false)} data-tip="Cerrar" data-for="close">
                <img src={IconClose} alt="Close" />
              </button>
            </div>
            <ReactTooltip id="close" />
          </Dialog>
        </Transition>
      )}
    </>
  )
}

export default ModalEdit55
