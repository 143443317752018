import { useEffect, useContext, useState } from 'react'
import IconExcel from '../../../images/excel.svg'
import { AppContext } from '../../../commons/context/App-context'
import HttpAxios from '../../../commons/hooks/http'
import { EErrorMsg } from '../../../commons/statics/controls'
import './table.scss'
import { IParameters } from '../../interfaces/items'

interface ITable {
  totalPerPage: number
  paramsExport: IParameters
  urlExport: string
}

interface ApiResponse {
  err?: boolean
  url?: string
  response?: string
}

function TableActions(props: ITable): JSX.Element {
  const { changeIsLoading, changeSnackBar } = useContext(AppContext)
  const [totalPerPage, setTotalPerPage] = useState<number>(0)
  const [paramsExport, setParamsExport] = useState<IParameters>({})
  const [urlExport, setUrlExport] = useState<string>('')

  const handleExport = () => {
    changeIsLoading(true)

    HttpAxios.post(urlExport, paramsExport)
      .then((res: { data: ApiResponse }) => {
        const { err, url, response } = res?.data || {}
        changeIsLoading(false)

        if (err) {
          changeSnackBar(EErrorMsg.generic)
        } else {
          handleDownload(url || response)
        }
      })
      .catch(() => {
        changeIsLoading(false)
        changeSnackBar(EErrorMsg.generic)
      })
  }

  const handleDownload = (urlFile = '') => {
    const link = document.createElement('a')
    link.href = urlFile
    link.setAttribute('download', `${urlFile.split('/')[urlFile.split('/').length - 1]}`)
    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      link?.parentNode?.removeChild(link)
    }, 100)
  }

  useEffect(() => {
    setTotalPerPage(props.totalPerPage)
    setParamsExport(props.paramsExport)
    setUrlExport(props.urlExport)
  }, [props])

  return (
    <>
      <div className="TableActions">
        <div className="TableTotal">{totalPerPage > 0 && 'Total de registros: ' + totalPerPage}</div>
        <div className="separate"></div>
        {totalPerPage > 0 && (
          <button className="button buttonExcel" type="button" onClick={handleExport}>
            <img src={IconExcel} alt="Excel" className="buttonIconLeft" />
            Exportar
          </button>
        )}
      </div>
    </>
  )
}

export default TableActions
