import * as AxiosLogger from 'axios-logger'
import environment from '../../environments/environment'
import { IUserMe } from '../interfaces/user'

const InterceptorConfig = async (config: any) => {
  const { url } = config
  let urlRest = url.replace(environment.apiURL, '')
  const arrUrl = urlRest.split('/')

  if (Number(arrUrl[arrUrl.length - 1])) {
    urlRest = urlRest.replace('/' + arrUrl[arrUrl.length - 1], '')
  }

  const itemUserMe = (await localStorage.getItem('userme')) || ''
  const userMe = itemUserMe ? (JSON.parse(itemUserMe) as IUserMe) : ({} as IUserMe)
  const hasAuthorization = [
    '/checklists',
    '/checklists/dashboard',
    '/checklists/dashboard_detail',
    '/checklists/dashboard_websearch',
    '/checklists/dashboard_websearch_xls',
    '/checklists/listar_visual_resultadoweb',
    '/checklists/listar_visual_resultadoweb_xls',
    '/checklists/visual_promedioweb',
    '/checklists/visual_promedioweb_xls',
    '/checklists/visual_resumenweb',
    '/checklists/visual_resumenweb_xls',
    '/entidad/estados',
    '/incidencias/web_search',
    '/incidencias/detalle_byid',
    '/incidencias/actualizar_atencion',
    '/incidencias/web_search_xls',
    '/incidencias/guardar',
    '/incidencias/getcabecera',
    '/incidencias/getcabecera_xls',
    '/incidencias/guardar_detalle',
    '/incidencias/producto_bysku',
    '/incidencias/cerrar',
    '/albumfoto/listar',
    '/albumfoto/delete',
    '/albumfoto/crearmultiple',
    '/mantenimiento/listar_web',
    '/mantenimiento/by_id',
    '/mantenimiento/actualizar_web',
    '/mantenimiento/listar_web_xls',
    '/cuadratura/search_web',
    '/cuadratura/listagrupo',
    '/cuadratura/listar_observacion_validacion',
    '/cuadratura/listadetalle_web',
    '/cuadratura/search_web_xls',
    '/cuadratura/diferencias_listaweb',
    '/cuadratura/diferencias_listaweb_xls',
    '/extorno/listar_web',
    '/extorno/listar_web_xls',
    '/extorno/by_id',
    '/extorno/actualizar_web',
    '/cuadratura/listaboveda_web',
    '/cuadratura/listaboveda_web_xls',
    '/auditoria/listar_web',
    '/auditoria/listar_web_xls',
    '/auditoria/resumenweb',
    '/mantenimiento/estadisticaweb',
    '/zonas/jefes',
    '/zonas/listar',
    '/contrato/listar',
    '/contrato/exportar_lista_xls',
    '/contrato/by_id',
    '/contrato/crear',
    '/auditoria/evaluacion_anual_web',
    '/auditoria/evaluacion_anual_web_xls',
    '/user/responsable_bydni',
    '/cuadratura/listawebcontable',
    '/cuadratura/listawebcontable_xls',
    '/cuadratura/getid_bycuadraturakey',
    '/cuadratura/aperturalink_descuento',
    '/cuadratura/actualizafirmadescuento',
    '/cuadratura/listar_observacion_byid',
    '/cuadratura/actualizar_observacion_iu'
  ]
  const token = userMe ? userMe?.token : ''

  if (hasAuthorization.join().indexOf(urlRest) > -1) {
    config.headers.authorization = `Bearer ${token}`
  }

  return AxiosLogger.requestLogger(config)
}

const InterceptorError = (err: any) => {
  return AxiosLogger.errorLogger(err)
}

const InterceptorResponseConfig = async (config: any) => {
  return AxiosLogger.responseLogger(config)
}

const InterceptorResponseError = (err: any) => {
  return AxiosLogger.errorLogger(err)
}

export { InterceptorConfig, InterceptorError, InterceptorResponseConfig, InterceptorResponseError }
