import { useContext, useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactDataGrid from 'react-data-grid'
import classnames from 'classnames'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import HttpAxios from '../../../commons/hooks/http'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import { EErrorMsg } from '../../../commons/statics/controls'
import TableActions from '../../../commons/components/table/TableActions'
import TablePaged from '../../../commons/components/table/TablePaged'
import { useUserMe } from '../../../commons/hooks/userme'
import { AppContext } from '../../../commons/context/App-context'
import { ESelectCustom, ESelectTheme } from '../../../commons/statics/controls'
import IconPdf from '../../../images/pdf.svg'
import IconExcel from '../../../images/excel.svg'
import { ITiendas } from '../../../commons/interfaces/user'
import environment from '../../../environments/environment'
import { Errors } from '../../../commons/constants/submodules'
import { IParameters } from '../../../commons/interfaces/items'
import { currencyFormat, currencyFormat3Dig } from '../../../commons/utils/utils'
import es from 'date-fns/locale/es'
import ApexChart from 'react-apexcharts'
import './view66.scss'

registerLocale('es', es)

const defaultOption = {
  value: 0,
  label: 'TODOS',
}
let params: IParameters = {}
let memoParams: IParameters = {}

function View66() {
  const { changeIsLoading, changeSnackBar, changeUrlBackTop, changePayload66, payload66 } = useContext(AppContext)
  const history = useHistory()
  const [noData, setNoData] = useState<boolean>(false)
  const compPDF = (data: any) => {
    return data?.row?.urlinforme ? (
      <a href={data.row.urlinforme} target="blank">
        <img className="View66Pdf" src={IconPdf} alt="PDF" />
      </a>
    ) : (
      <></>
    )
  }
  const compPercentage = (data: any) => {
    return parseInt(data) < 90 ? <span style={{ color: '#CC0000' }}>{data}</span> : <span>{data}</span>
  }
  const compResumen = (data: any) => {
    return data?.row?.urlformato ? <a target="blank">Ver resumen</a> : <></>
  }
  const headerColumns = [
    { key: 'tienda', name: 'TIENDA', width: '20%', minWidth: 50 },
    { key: 'jefetienda', name: 'JEFE TIENDA', width: '20%', minWidth: 50 },
    { key: 'jefezonal', name: 'JEFE ZONAL', width: '20%', minWidth: 50 },
    {
      key: 'tipochecklist',
      name: 'TIPO CHECKLIST',
      width: '20%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'ubicacion',
      name: 'UBICACIÓN',
      width: '10%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'porcentajeevaluacion',
      name: '% EVALUACIÓN',
      width: '10%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.porcentajeevaluacion),
      cellClass: () => {
        return 'colpercentage'
      },
    },
  ]
  const [msgError, setMsgError] = useState<string>('')
  const [useMe, setUseMe] = useUserMe()
  const [stores, setStores] = useState<Array<ITiendas> | null>(null)
  const [checklists, setChecklists] = useState([])
  const [status, setStatus] = useState([])
  const [lists, setLists] = useState({ value: 21, label: 'Checklist Revisión Visual Tienda' })
  const [stateAttention, setStateAttention] = useState(defaultOption)
  const [store, setStore] = useState(defaultOption)
  const [rows, setRows] = useState<Array<any>>([])
  const [columns, setColumns] = useState(headerColumns)
  const [parameters, setParameters] = useState<IParameters>({})
  const [totalPerPage, setTotalPerPage] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [totalPages, setTotalPages] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const selectStyles = ESelectCustom
  const selectTheme = ESelectTheme
  const [endDate, setEndDate] = useState(new Date())
  const subsDays = dayjs(new Date()).subtract(7, 'day')
  const [startDate, setStartDate] = useState(subsDays.toDate())
  const [fullName, setFullName] = useState('')
  const btnSearchRef = useRef<any>(null)
  const [chartVisualPromedio, setChartVisualPromedio] = useState({
    series: [
      {
        type: 'column',
        data: [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1],
      },
      colors: ['#4f81bd'],
      title: {
        text: '% EVALUACIÓN POR TIENDA',
        offsetX: 110,
      },
      xaxis: {
        categories: [],
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#000',
          },
          labels: {
            style: {
              colors: '#000',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 30,
          offsetX: 60,
        },
      },
    },
  })

  const getItems = (items: any, hasDefault?: boolean): any => {
    let temp: any = hasDefault ? [] : [defaultOption]

    items?.forEach((s: any) => {
      temp.push({
        value: s.id,
        label: s.descripcion,
      })
    })

    return temp
  }

  const getTypes = () => {
    HttpAxios.post('/checklists', {
      idmodulo: 22,
      todos: false,
      identidad: 0,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setChecklists(options)
        if (payload66) {
          const elem = options.filter((el: any) => el.value === payload66?.idcuadratura)
          if (elem) {
            onChangeChecklists(elem[0])
          }
          searchByBack()
        }
        changePayload66(null)
      })
      .catch((err) => {
        changePayload66(null)
      })
  }

  const getStatus = () => {
    HttpAxios.post('/entidad/estados', {
      id: 19,
      idestadopadre: 0,
      todos: true,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setStatus(options)
        if (payload66) {
          const elem = options.filter((el: any) => el.value === payload66?.idestado)
          if (elem) {
            onChangeStateAtention(elem[0])
          }
        }
        getTypes()
      })
      .catch((err) => {
        getTypes()
      })
  }

  const handleExport = () => {
    changeIsLoading(true)

    HttpAxios.post('/checklists/visual_promedioweb_xls', parameters)
      .then((res: any) => {
        const { err, url, response } = res?.data || {}
        changeIsLoading(false)

        if (err) {
          changeSnackBar(EErrorMsg.generic)
        } else {
          handleDownload(url || response)
        }
      })
      .catch(() => {
        changeIsLoading(false)
        changeSnackBar(EErrorMsg.generic)
      })
  }

  const handleDownload = (urlFile: string = '') => {
    const link = document.createElement('a')
    link.href = urlFile
    link.setAttribute('download', `${urlFile.split('/')[urlFile.split('/').length - 1]}`)
    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      link?.parentNode?.removeChild(link)
    }, 100)
  }

  const search = (page: number = payload66?.page || 1, isPaginated?: boolean) => {
    if (!isPaginated) {
      const rangeStart = startDate ? dayjs(startDate).format(environment.search.formatDate) : ''
      const rangeEnd = endDate ? dayjs('' + endDate).format(environment.search.formatDate) : ''

      params = {
        fecha_ini: rangeStart,
        fecha_fin: rangeEnd || rangeStart,
        idcheck: lists?.value || 21,
      }
      memoParams = params
    }

    let paramsSearch = params
    paramsSearch.page = page
    changeIsLoading(true)
    setInitLoading(true)
    setMsgError('')
    setRows([])

    HttpAxios.post('/checklists/visual_promedioweb', paramsSearch)
      .then((res: any) => {
        const data = res?.data || {}
        const response = data.response || []
        if (response.length) {
          const { nextpage = 2, page = 1, total_pages = 0, total_rows = 0 } = data
          setNoData(false)
          setColumns(headerColumns)
          setRows(response)
          setDataChartVisualPromedio(response)
          setTotalPerPage(total_rows || response.length)
          setActivePage(page)
          setTotalPages(total_pages)
          setNextPage(nextpage)
          delete memoParams.limit
          delete memoParams.page
          setParameters(memoParams)
          setMsgError('')
        } else {
          setNoData(true)
          setRows([])
        }

        changeIsLoading(false)
        setInitLoading(false)
      })
      .catch(() => {
        setMsgError(Errors.generic)
        changeIsLoading(false)
        setInitLoading(false)
      })
  }

  const setDataChartVisualPromedio = (response: any) => {
    const chartTemp = { ...chartVisualPromedio }
    chartTemp.options = {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1],
      },
      colors: ['#4f81bd'],
      title: {
        text: '% EVALUACIÓN POR TIENDA',
        offsetX: 110,
      },
      xaxis: {
        categories: response.map((item: any) => {
          return item.tienda
        }),
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#000',
          },
          labels: {
            style: {
              colors: '#000',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 30,
          offsetX: 60,
        },
      },
    }
    chartTemp.series = [
      {
        type: 'column',
        data: response.map((item: any) => {
          return item.porcentajeevaluacion || 0
        }),
      },
    ]
    setChartVisualPromedio(chartTemp)
  }

  const handlePaged = (ind: number) => {
    search(ind, true)
  }

  const onChangeChecklists = (evt: any) => {
    setLists(evt)
  }

  const onChangeStateAtention = (evt: any) => {
    setStateAttention(evt)
  }

  const onChangeStores = (evt: any) => {
    setStore(evt)
  }

  const onChangeRange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const initBackValues = (stores: Array<ITiendas> | null) => {
    if (payload66) {
      const { fecha_ini, fecha_fin } = payload66
      const y: number = Number(fecha_ini.substring(0, 4))
      const m: number = Number(fecha_ini.substring(4, 6)) - 1
      const d: number = Number(fecha_ini.substring(6, 8))
      const yEnd: number = Number(fecha_fin.substring(0, 4))
      const mEnd: number = Number(fecha_fin.substring(4, 6)) - 1
      const dEnd: number = Number(fecha_fin.substring(6, 8))

      setStartDate(new Date(y, m, d))
      setEndDate(new Date(yEnd, mEnd, dEnd))

      const elem: any = (stores || []).filter((el: any) => el.id === payload66?.idtienda)
      if (elem) {
        const el = getItems(elem)
        onChangeStores(el.length === 1 ? el[0] : el[1])
      }

      setFullName(payload66?.supervisor! || '')
    }
  }

  const searchByBack = () => {
    if (payload66 && lists && btnSearchRef) {
      btnSearchRef.current.click()
    }
  }

  useEffect(() => {
    const stores: Array<ITiendas> | null = useMe?.tiendas || null
    setStores(stores)
    initBackValues(stores)
    getStatus()
    !payload66 && search()
    changeUrlBackTop('/submodulo/66')
    changeIsLoading(true)
  }, [])

  return (
    <>
      <div className="View66Search">
        <div className="row-6">
          <div className="col">
            <label className="label">Tipo CheckList</label>
            <Select id="lists" name="lists" styles={selectStyles} theme={selectTheme} value={lists} onChange={(evt) => onChangeChecklists(evt)} placeholder="Selecciona un tipo" options={checklists} />
          </div>
          <div className="col">
            <label className="label">Rango de fechas</label>
            <DatePicker
              selected={startDate}
              onChange={onChangeRange}
              startDate={startDate}
              endDate={endDate}
              selectsRange={true}
              locale="es"
              className="input"
              dateFormat="dd/MM/yyyy"
              placeholderText="Selecione un rango de fecha"
              maxDate={new Date()}
            />
          </div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button type="button" onClick={() => search()} className="button buttonFull" ref={btnSearchRef}>
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div className="View66Body">
        {noData || msgError ? (
          !initLoading && (
            <div
              className={classnames({
                noData: noData,
                msgError: msgError,
              })}
            >
              {msgError ? msgError : 'No se encontró resultado en la búsqueda'}
            </div>
          )
        ) : (
          <>
            <div className="View66SearchActions">
              <div className="View66SearchActionsTotal">{totalPerPage > 0 && 'Total de registros: ' + totalPerPage}</div>
              <div className="separate"></div>
              {totalPerPage > 0 && (
                <button className="button buttonExcel" type="button" onClick={handleExport}>
                  <img src={IconExcel} alt="Excel" className="buttonIconLeft" />
                  Exportar
                </button>
              )}
            </div>
            <ReactDataGrid
              className="View66Table"
              columns={columns}
              rows={rows}
              onRowsChange={setRows}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
            />
            <TablePaged msgError={msgError} activePage={activePage} handlePaged={handlePaged} totalPages={totalPages} />
            <div className="row-2">
              <div className="col chart-panel">
                <div className="chart-panel__item">
                  <ApexChart options={chartVisualPromedio.options} series={chartVisualPromedio.series} type="line" width="100%" height={540} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default View66
