import { useContext, useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import HttpAxios from '../../../commons/hooks/http'
import DatePicker, { registerLocale } from 'react-datepicker'
import { EErrorMsg, ESelectCustom, ESelectTheme } from '../../../commons/statics/controls'
import { useUserMe } from '../../../commons/hooks/userme'
import { AppContext } from '../../../commons/context/App-context'
import IconPdf from '../../../images/pdf.svg'
import { ITiendas } from '../../../commons/interfaces/user'
import environment from '../../../environments/environment'
import { Errors } from '../../../commons/constants/submodules'
import { IParameters } from '../../../commons/interfaces/items'
import es from 'date-fns/locale/es'
import ApexChart from 'react-apexcharts'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import './view63.scss'
import logo from '../../../images/logoreporte.png'

registerLocale('es', es)

const defaultOption = {
  value: 0,
  label: 'TODOS',
}
let params: IParameters = {}
let memoParams: IParameters = {}

type RowType = {
  resumen_requerimientos: any
  atendidos: any
  atendidos_proveedor: any
}

function View63() {
  const { changeIsLoading, changeSnackBar, changeUrlBackTop, changePayload63, payload63 } = useContext(AppContext)
  const history = useHistory()
  const [noData, setNoData] = useState<boolean>(false)
  const [msgError, setMsgError] = useState<string>('')
  const [useMe, setUseMe] = useUserMe()
  const [stores, setStores] = useState<Array<ITiendas> | null>(null)
  const [checklists, setChecklists] = useState([])
  const [status, setStatus] = useState([])
  const [lists, setLists] = useState({ value: 316, label: 'Auditoria Operativa' })
  const [stateAttention, setStateAttention] = useState(defaultOption)
  const [store, setStore] = useState(defaultOption)
  const [rows, setRows] = useState<RowType | null>(null)
  const [parameters, setParameters] = useState<IParameters>({})
  const [totalPerPage, setTotalPerPage] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [totalPages, setTotalPages] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const selectStyles = ESelectCustom
  const selectTheme = ESelectTheme
  const [endDate, setEndDate] = useState(new Date())
  const subsDays = dayjs(new Date()).subtract(7, 'day')
  const [startDate, setStartDate] = useState(subsDays.toDate())
  const [fullName, setFullName] = useState('')
  const btnSearchRef = useRef<any>(null)
  const [isActive, setIsActive] = useState(false)
  const [chartLima, setChartLima] = useState({
    series: [
      {
        name: 'Requerimientos Abiertos',
        type: 'column',
        data: [],
      },
      {
        name: 'Requerimientos Cerrados',
        type: 'column',
        data: [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1],
      },
      colors: ['#4f81bd', '#c0504d'],
      title: {
        text: 'Requerimientos Tiendas Lima',
        offsetX: 110,
      },
      xaxis: {
        categories: [],
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#000',
          },
          labels: {
            style: {
              colors: '#000',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 30,
          offsetX: 60,
        },
      },
    },
  })
  const [chartProvincias, setChartProvincias] = useState({
    series: [
      {
        name: 'Requerimientos Abiertos',
        type: 'column',
        data: [],
      },
      {
        name: 'Requerimientos Cerrados',
        type: 'column',
        data: [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1],
      },
      colors: ['#4f81bd', '#9bbb59'],
      title: {
        text: 'Requerimientos Tiendas Provincias',
        offsetX: 110,
      },
      xaxis: {
        categories: [],
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#000',
          },
          labels: {
            style: {
              colors: '#000',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 30,
          offsetX: 60,
        },
      },
    },
  })
  const [chartCategorias, setChartCategorias] = useState({
    series: [
      {
        data: [],
      },
    ],
    options: {
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#000'],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
      },
      colors: ['#c5d9ff'],
      title: {
        text: 'Requerimientos por Categoría',
        offsetX: 110,
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: [],
      },
    },
  })
  const [chartCategoriasPercentage, setChartCategoriasPercentage] = useState({
    series: [],
    options: {
      title: {
        text: 'Requerimientos por Categoría %',
        offsetX: 110,
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      responsive: [
        {
          breakpoint: 1900,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      labels: [],
    },
  })
  const [chartRequerimientosAtendidos, setChartRequerimientosAtendidos] = useState({
    series: [],
    options: {
      title: {
        text: 'Requerimientos Atendidos %',
        offsetX: 110,
      },
      colors: ['#4e80bb', '#99b958'],
      labels: [],
      responsive: [
        {
          breakpoint: 1900,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  })
  const [chartRequerimientosAtendidosProveedor, setChartRequerimientosAtendidosProveedor] = useState({
    series: [],
    options: {
      title: {
        text: 'Requerimientos Atendidos por Proveedor %',
        offsetX: 110,
      },
      colors: ['#f78a28', '#33a8c8'],
      labels: [],
      responsive: [
        {
          breakpoint: 1900,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  })

  const getItems = (items: any, hasDefault?: boolean): any => {
    let temp: any = hasDefault ? [] : [defaultOption]

    items?.forEach((s: any) => {
      temp.push({
        value: s.id,
        label: s.descripcion,
      })
    })

    return temp
  }

  const getTypes = () => {
    HttpAxios.post('/entidad/estados', {
      id: 33,
      idestadopadre: 0,
      todos: false,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setChecklists(options)
        if (payload63) {
          const elem = options.filter((el: any) => el.value === payload63?.idcuadratura)
          if (elem) {
            onChangeChecklists(elem[0])
          }
          searchByBack()
        }
        changePayload63(null)
      })
      .catch((err) => {
        changePayload63(null)
      })
  }

  const getStatus = () => {
    HttpAxios.post('/entidad/estados', {
      id: 19,
      idestadopadre: 0,
      todos: true,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setStatus(options)
        if (payload63) {
          const elem = options.filter((el: any) => el.value === payload63?.idestado)
          if (elem) {
            onChangeStateAtention(elem[0])
          }
        }
        getTypes()
      })
      .catch((err) => {
        getTypes()
      })
  }

  const handleExportDocument = async () => {
    const element = document.getElementById('divToPrint') as HTMLElement
    element.classList.toggle('printer')
    const doc = new jsPDF('p', 'mm', 'a4')

    let forPDF = document.getElementById('b-block') as HTMLElement
    const clonedTarget: any = forPDF?.cloneNode(true)
    document.getElementById('divToPrintClone')?.appendChild(clonedTarget)

    await html2canvas(forPDF).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const imgWidth = 210
      let imgHeight = (canvas.height * imgWidth) / canvas.width
      doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight + 10)
    })

    forPDF = document.getElementById('c-block') as HTMLElement
    await html2canvas(forPDF).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const imgWidth = 210
      let imgHeight = (canvas.height * imgWidth) / canvas.width

      doc.addPage()
      doc.addImage(imgData, 'PNG', 0, 50, imgWidth, imgHeight + 10)
    })

    forPDF = document.getElementById('d-block') as HTMLElement
    await html2canvas(forPDF).then((canvas) => {
      const imgData = canvas.toDataURL('image/png')
      const imgWidth = 210
      let imgHeight = (canvas.height * imgWidth) / canvas.width

      doc.addPage()
      doc.addImage(imgData, 'PNG', 0, 50, imgWidth, imgHeight + 10)
    })

    doc.save('reportes-mantenimiento-servicio.pdf')
    element.classList.toggle('printer')
  }

  const handleDownload = (urlFile: string = '') => {
    const link = document.createElement('a')
    link.href = urlFile
    link.setAttribute('download', `${urlFile.split('/')[urlFile.split('/').length - 1]}`)
    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      link?.parentNode?.removeChild(link)
    }, 100)
  }

  const search = (page: number = payload63?.page || 1, isPaginated?: boolean) => {
    if (!isPaginated) {
      const rangeStart = startDate ? dayjs(startDate).format(environment.search.formatDate) : ''
      const rangeEnd = endDate ? dayjs('' + endDate).format(environment.search.formatDate) : ''

      params = {
        fecha_ini: rangeStart,
        fecha_fin: rangeEnd || rangeStart,
      }
      memoParams = params
    }

    let paramsSearch = params
    paramsSearch.page = page
    memoParams.page = page
    changeIsLoading(true)
    setInitLoading(true)
    setMsgError('')
    setRows(null)

    HttpAxios.post('/mantenimiento/estadisticaweb', paramsSearch)
      .then((res: any) => {
        const data = res?.data || {}
        const response = data.response || []
        if (response) {
          setRows(response)
          setParameters(memoParams)
          setMsgError('')
          setDataChartLima(response)
          setDataChartProvincias(response)
          setDataChartCategorias(response)
          setDataChartCategoriasPercentage(response)
          setDataChartRequerimientosAtendidos(response)
          setDataChartRequerimientosAtendidosProveedor(response)
          setNoData(false)
        } else {
          setNoData(true)
          setRows(null)
        }

        changeIsLoading(false)
        setInitLoading(false)
      })
      .catch(() => {
        setMsgError(Errors.generic)
        changeIsLoading(false)
        setInitLoading(false)
      })
  }

  const setDataChartLima = (response: any) => {
    const chartTemp = { ...chartLima }
    chartTemp.options = {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1],
      },
      colors: ['#4f81bd', '#c0504d'],
      title: {
        text: 'Requerimientos Tiendas Lima',
        offsetX: 110,
      },
      xaxis: {
        categories: response.lima.map((item: any) => {
          return item.nombretienda
        }),
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#000',
          },
          labels: {
            style: {
              colors: '#000',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 30,
          offsetX: 60,
        },
      },
    }
    chartTemp.series = [
      {
        name: 'Requerimientos Abiertos',
        type: 'column',
        data: response.lima.map((item: any) => {
          return item.abiertos || 0
        }),
      },
      {
        name: 'Requerimientos Cerrados',
        type: 'column',
        data: response.lima.map((item: any) => {
          return item.cerrados || 0
        }),
      },
    ]
    setChartLima(chartTemp)
  }

  const setDataChartProvincias = (response: any) => {
    const chartTemp = { ...chartProvincias }
    chartTemp.options = {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1],
      },
      colors: ['#4f81bd', '#9bbb59'],
      title: {
        text: 'Requerimientos Tiendas Provincias',
        offsetX: 110,
      },
      xaxis: {
        categories: response.provincias.map((item: any) => {
          return item.nombretienda
        }),
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#000',
          },
          labels: {
            style: {
              colors: '#000',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 30,
          offsetX: 60,
        },
      },
    }
    chartTemp.series = [
      {
        name: 'Requerimientos Abiertos',
        type: 'column',
        data: response.provincias.map((item: any) => {
          return item.abiertos || 0
        }),
      },
      {
        name: 'Requerimientos Cerrados',
        type: 'column',
        data: response.provincias.map((item: any) => {
          return item.cerrados || 0
        }),
      },
    ]
    setChartProvincias(chartTemp)
  }

  const setDataChartCategorias = (response: any) => {
    const chartTemp = { ...chartCategorias }
    chartTemp.options = {
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: '12px',
          colors: ['#000'],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#fff'],
      },
      colors: ['#c5d9ff'],
      title: {
        text: 'Requerimientos por Categoría',
        offsetX: 110,
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: response.categorias.map((item: any) => {
          return item.descripcion
        }),
      },
    }
    chartTemp.series = [
      {
        data: response.categorias.map((item: any) => {
          return item.cantidad || 0
        }),
      },
    ]
    setChartCategorias(chartTemp)
  }

  const setDataChartCategoriasPercentage = (response: any) => {
    const chartTemp = { ...chartCategoriasPercentage }
    chartTemp.options = {
      title: {
        text: 'Requerimientos por Categoría %',
        offsetX: 110,
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      responsive: [
        {
          breakpoint: 1900,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      labels: response.categorias.map((item: any) => {
        return item.descripcion
      }),
    }
    chartTemp.series = response.categorias.map((item: any) => {
      return item.porcentaje || 0
    })
    setChartCategoriasPercentage(chartTemp)
  }

  const setDataChartRequerimientosAtendidos = (response: any) => {
    const chartTemp = { ...chartRequerimientosAtendidos }
    chartTemp.options = {
      title: {
        text: 'Requerimientos Atendidos %',
        offsetX: 110,
      },
      colors: ['#4e80bb', '#99b958'],
      labels: response.atendidos.map((item: any) => {
        return item.requerimiento
      }),
      responsive: [
        {
          breakpoint: 1900,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }
    chartTemp.series = response.atendidos.map((item: any) => {
      return item.cantidad || 0
    })
    setChartRequerimientosAtendidos(chartTemp)
  }

  const setDataChartRequerimientosAtendidosProveedor = (response: any) => {
    const chartTemp = { ...chartRequerimientosAtendidosProveedor }
    chartTemp.options = {
      title: {
        text: 'Requerimientos Atendidos por Proveedor %',
        offsetX: 110,
      },
      colors: ['#f78a28', '#33a8c8'],
      labels: response.atendidos_proveedor.map((item: any) => {
        return item.proveedor
      }),
      responsive: [
        {
          breakpoint: 1900,
          options: {
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }
    chartTemp.series = response.atendidos_proveedor.map((item: any) => {
      return item.cantidad || 0
    })
    setChartRequerimientosAtendidosProveedor(chartTemp)
  }

  const handlePaged = (ind: number) => {
    search(ind, true)
  }

  const onChangeChecklists = (evt: any) => {
    setLists(evt)
  }

  const onChangeFullName = (evt: any) => {
    setFullName(evt.target.value)
  }

  const onChangeStateAtention = (evt: any) => {
    setStateAttention(evt)
  }

  const onChangeStores = (evt: any) => {
    setStore(evt)
  }

  const onChangeRange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const pad = (s: any) => {
    return s < 10 ? '0' + s : s
  }
  const convertDate = (inputFormat: any) => {
    const d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-')
  }

  const rowClick = (obj: any, r: any): void => {
    const allows = [0, 1, 2, 3, 4, 5]
    changePayload63(memoParams)
    if (allows.includes(r.idx)) {
      const url = '/detalle/submodulo/63/' + obj?.idcuadratura
      history.push(url)
    } else if (r.idx === 6) {
      obj.urlformato && window.open(obj.urlformato, '_blank')
    }
  }

  const initBackValues = (stores: Array<ITiendas> | null) => {
    if (payload63) {
      const { fecha_ini, fecha_fin } = payload63
      const y: number = Number(fecha_ini.substring(0, 4))
      const m: number = Number(fecha_ini.substring(4, 6)) - 1
      const d: number = Number(fecha_ini.substring(6, 8))
      const yEnd: number = Number(fecha_fin.substring(0, 4))
      const mEnd: number = Number(fecha_fin.substring(4, 6)) - 1
      const dEnd: number = Number(fecha_fin.substring(6, 8))

      setStartDate(new Date(y, m, d))
      setEndDate(new Date(yEnd, mEnd, dEnd))

      const elem: any = (stores || []).filter((el: any) => el.id === payload63?.idtienda)
      if (elem) {
        const el = getItems(elem)
        onChangeStores(el.length === 1 ? el[0] : el[1])
      }

      setFullName(payload63?.supervisor! || '')
    }
  }

  const searchByBack = () => {
    if (payload63 && lists && btnSearchRef) {
      btnSearchRef.current.click()
    }
  }

  useEffect(() => {
    const stores: Array<ITiendas> | null = useMe?.tiendas || null
    setStores(stores)
    initBackValues(stores)
    getStatus()
    !payload63 && search()
    changeUrlBackTop('/submodulo/63')
    changeIsLoading(true)
  }, [])

  return (
    <>
      <div className="View63Search">
        <div className="row-6">
          <div className="col">
            <label className="label">Rango de fechas</label>
            <DatePicker
              selected={startDate}
              onChange={onChangeRange}
              startDate={startDate}
              endDate={endDate}
              selectsRange={true}
              locale="es"
              className="input"
              dateFormat="dd/MM/yyyy"
              placeholderText="Selecione un rango de fecha"
              maxDate={new Date()}
            />
          </div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button type="button" onClick={() => search()} className="button buttonFull" ref={btnSearchRef}>
              Buscar
            </button>
          </div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button className="button buttonPDF" type="button" onClick={handleExportDocument}>
              Exportar
              <img src={IconPdf} alt="PDF" className="buttonIconLeft" />
            </button>
          </div>
          <div className="col"></div>
        </div>
      </div>

      <div className="View63Body">
        {noData || msgError ? (
          !initLoading && (
            <div
              className={classnames({
                noData: noData,
                msgError: msgError,
              })}
            >
              {msgError ? msgError : 'No se encontró resultado en la búsqueda'}
            </div>
          )
        ) : (
          <>
            <div id="divToPrint">
              <div id="b-block" className="row-2">
                <div className="logo">
                  <div className="logo__container">
                    <img src={logo} alt="Empresas que transforman el Perú" />
                  </div>
                  <h1>
                    Reporte Mantenimiento y Servicio del {convertDate(startDate)} al {convertDate(endDate)}
                  </h1>
                </div>
                <div className="col chart-panel">
                  <div className="chart-panel__item">
                    <ApexChart options={chartLima.options} series={chartLima.series} type="line" width="100%" height={350} />
                  </div>
                  {rows && rows?.resumen_requerimientos?.length && (
                    <>
                      <div className="chart-panel__table">
                        <div className="tableResumen">
                          <div className="tableResumen__header">
                            <div className="tableResumen__header__col">Tiendas</div>
                            <div className="tableResumen__header__col">
                              Requerimientos
                              <br />
                              Cerrados
                            </div>
                            <div className="tableResumen__header__col">
                              Requerimientos
                              <br />
                              Abiertos
                            </div>
                            <div className="tableResumen__header__col">Total</div>
                          </div>
                          <div className="tableResumen__body">
                            {rows?.resumen_requerimientos.map((item: any, index: number) => {
                              return (
                                <>
                                  <div className="tableResumen__body__col">{item.zona}</div>
                                  <div className="tableResumen__body__col">{item.cerrados}</div>
                                  <div className="tableResumen__body__col">{item.abiertos}</div>
                                  <div className="tableResumen__body__col">{item.total}</div>
                                </>
                              )
                            })}
                          </div>
                          <div className="tableResumen__footer">
                            <div className="tableResumen__footer__col">TOTAL</div>
                            <div className="tableResumen__footer__col">
                              {rows?.resumen_requerimientos.reduce((acum: number, item: any) => {
                                return acum + parseInt(item.cerrados)
                              }, 0)}
                            </div>
                            <div className="tableResumen__footer__col">
                              {rows?.resumen_requerimientos.reduce((acum: number, item: any) => {
                                return acum + parseInt(item.abiertos)
                              }, 0)}
                            </div>
                            <div className="tableResumen__footer__col">
                              {rows?.resumen_requerimientos.reduce((acum: number, item: any) => {
                                return acum + parseInt(item.total)
                              }, 0)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col chart-panel">
                  <div className="chart-panel__item">
                    <ApexChart options={chartProvincias.options} series={chartProvincias.series} type="line" width="100%" height={350} />
                  </div>
                </div>
              </div>
              <div id="c-block" className="row-2">
                <div className="col chart-panel">
                  <div className="chart-panel__item">
                    <ApexChart options={chartCategorias.options} series={chartCategorias.series} type="bar" width="100%" height={350} />
                  </div>
                </div>
                <div className="col chart-panel">
                  <div className="chart-panel__item">
                    <ApexChart options={chartCategoriasPercentage.options} series={chartCategoriasPercentage.series} type="donut" width="100%" height={350} />
                  </div>
                </div>
              </div>
              <div id="d-block" className="row-2">
                <div className="col chart-panel">
                  <div className="chart-panel__item">
                    <ApexChart options={chartRequerimientosAtendidos.options} series={chartRequerimientosAtendidos.series} type="pie" width="100%" height={350} />
                  </div>
                  {rows && rows?.atendidos?.length && (
                    <>
                      <div className="chart-panel__table">
                        <div className="tableResumen">
                          <div className="tableResumen__header atendidos">
                            <div className="tableResumen__header__col">Estado de Requerimientos</div>
                            <div className="tableResumen__header__col">Total</div>
                          </div>
                          <div className="tableResumen__body atendidos">
                            {rows?.atendidos.map((item: any, index: number) => {
                              return (
                                <>
                                  <div className="tableResumen__body__col">{item.requerimiento}</div>
                                  <div className="tableResumen__body__col">{item.cantidad}</div>
                                </>
                              )
                            })}
                          </div>
                          <div className="tableResumen__footer atendidos">
                            <div className="tableResumen__footer__col">TOTAL REQUERIMIENTOS</div>
                            <div className="tableResumen__footer__col">
                              {rows?.atendidos.reduce((acum: number, item: any) => {
                                return acum + parseInt(item.cantidad)
                              }, 0)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col chart-panel">
                  <div className="chart-panel__item">
                    <ApexChart options={chartRequerimientosAtendidosProveedor.options} series={chartRequerimientosAtendidosProveedor.series} type="pie" width="100%" height={350} />
                  </div>
                  {rows && rows?.atendidos_proveedor?.length && (
                    <>
                      <div className="chart-panel__table">
                        <div className="tableResumen">
                          <div className="tableResumen__header atendidos">
                            <div className="tableResumen__header__col">Requerimientos por proveedor</div>
                            <div className="tableResumen__header__col">Total</div>
                          </div>
                          <div className="tableResumen__body atendidos">
                            {rows?.atendidos_proveedor.map((item: any, index: number) => {
                              return (
                                <>
                                  <div className="tableResumen__body__col">{item.proveedor}</div>
                                  <div className="tableResumen__body__col">{item.cantidad}</div>
                                </>
                              )
                            })}
                          </div>
                          <div className="tableResumen__footer atendidos">
                            <div className="tableResumen__footer__col">TOTAL REQUERIMIENTOS</div>
                            <div className="tableResumen__footer__col">
                              {rows?.atendidos_proveedor.reduce((acum: number, item: any) => {
                                return acum + parseInt(item.cantidad)
                              }, 0)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default View63
