import PackageJSON from '../../package.json'

const environment = {
  apiURL: 'https://wj7m7mqw87.execute-api.us-east-1.amazonaws.com/api/',
  reCaptcha: '6LeF8igbAAAAAPxfvjHYnNdeFeAeuQ16_oic2BEU',
  search: {
    limitPerPage: 20,
    formatDate: 'YYYYMMDD',
  },
  isCaptcha: false,
  version: PackageJSON.version,
  awsAmplify: {
    Auth: {
      identityPoolId: 'us-east-1:33937954-9ef4-4ca7-a889-0425de869d3e',
      region: 'us-east-1',
    },
    Storage: {
      AWSS3: {
        bucket: 'empresasquetransforman.dev',
        region: 'us-east-1',
      },
    },
  },
}

export default environment
