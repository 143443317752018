import { useContext, useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactDataGrid from 'react-data-grid'
import classnames from 'classnames'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import HttpAxios from '../../../commons/hooks/http'
import Select, { components } from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import { EErrorMsg, ESelectCustom, ESelectTheme } from '../../../commons/statics/controls'
import TableActions from '../../../commons/components/table/TableActions'
import TablePaged from '../../../commons/components/table/TablePaged'
import { useUserMe } from '../../../commons/hooks/userme'
import { AppContext } from '../../../commons/context/App-context'
import IconExcel from '../../../images/excel.svg'
import { ITiendas } from '../../../commons/interfaces/user'
import { Errors } from '../../../commons/constants/submodules'
import { IParameters } from '../../../commons/interfaces/items'
import environment from '../../../environments/environment'
import ApexChart from 'react-apexcharts'
import es from 'date-fns/locale/es'
import './view68.scss'

registerLocale('es', es)

const defaultOption = {
  value: 0,
  label: 'TODOS',
}
let params: IParameters = {}
let memoParams: IParameters = {}

function View68() {
  const { changeIsLoading, changeSnackBar, changeUrlBackTop, changePayload68, payload68, changeShowModalEdit68 } = useContext(AppContext)
  const history = useHistory()
  const [noData, setNoData] = useState<boolean>(false)
  const [year, setYear] = useState({ value: 2022, label: '2022' })

  const compCheck = (data: any, item: string) => {
    return parseInt(data?.row[item]) < parseInt(data?.row?.porcentajeminimo) ? (
      <span style={{ color: '#CC0000' }} title={`porcentaje mínimo es: ${data?.row?.porcentajeminimo}`}>
        {data?.row[item]}
      </span>
    ) : (
      <span>{data?.row[item]}</span>
    )
  }

  const headerColumns = [
    {
      key: 'zona',
      name: 'ZONA',
      width: '20%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    { key: 'tienda', name: 'TIENDA', width: '20%', minWidth: 50 },
    {
      key: 'anioanterior',
      name: `RESULTADO ${year?.value - 1}`,
      width: '20%',
      minWidth: 50,
      formatter: (data: any) => compCheck(data, 'anioanterior'),
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'aniobase',
      name: `RESULTADO ${year?.value}`,
      width: '20%',
      minWidth: 50,
      formatter: (data: any) => compCheck(data, 'aniobase'),
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'variacion',
      name: 'VARIACIÓN',
      width: '20%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
  ]
  const [msgError, setMsgError] = useState<string>('')
  const [useMe, setUseMe] = useUserMe()
  const [stores, setStores] = useState<Array<ITiendas> | null>(null)
  const [checklists, setChecklists] = useState([])
  const [status, setStatus] = useState([])
  const [lists, setLists] = useState({ value: 21, label: 'Checklist Revisión Visual Tienda' })
  const [auditoria, setAuditoria] = useState({ value: 316, label: 'Auditoria Operativa' })
  const [checkauditorias, setCheckAuditorias] = useState([])
  const [jefe, setJefe] = useState({ value: 0, label: 'TODOS' })
  const [checkjefes, setCheckjefes] = useState([])
  const [years, setYears] = useState<Array<any>>([])
  const [stateAttention, setStateAttention] = useState(defaultOption)
  const [store, setStore] = useState([defaultOption])
  const [rows, setRows] = useState<Array<any>>([])
  const [columns, setColumns] = useState(headerColumns)
  const [parameters, setParameters] = useState<IParameters>({})
  const [totalPerPage, setTotalPerPage] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [totalPages, setTotalPages] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const selectStyles = ESelectCustom
  const selectTheme = ESelectTheme
  const [endDate, setEndDate] = useState(new Date())
  const subsDays = dayjs(new Date()).subtract(7, 'day')
  const [startDate, setStartDate] = useState(subsDays.toDate())
  const [fullName, setFullName] = useState('')
  const btnSearchRef = useRef<any>(null)
  const [rowSelected, setRowSelected] = useState(null)
  const [idxSelected, setIdxSelected] = useState(null)
  const [statusSelected, setStatusSelected] = useState([])

  const [chart, setChart] = useState<any>({
    series: [
      {
        name: 'Resultado 2021',
        type: 'column',
        data: [],
      },
      {
        name: 'Resultado 2022',
        type: 'column',
        data: [],
      },
      {
        name: 'Variación',
        type: 'column',
        data: [],
      },
    ],
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1],
      },
      colors: ['#4f81bd', '#9bbb59', '#506621'],
      title: {
        text: 'Comparativo de Resultado de Auditorias Operativas Anuales',
        offsetX: 110,
      },
      xaxis: {
        categories: [],
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#000',
          },
          labels: {
            style: {
              colors: '#000',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 30,
          offsetX: 60,
        },
      },
    },
  })

  const getItems = (items: any, hasDefault?: boolean): any => {
    let temp: any = hasDefault ? [] : [defaultOption]

    items?.forEach((s: any) => {
      temp.push({
        value: !isNaN(s.id) ? s.id : !isNaN(s.idsector) ? s.idsector : !isNaN(s.idjefezonal) ? s.idjefezonal : 0,
        label: s.descripcion || s.nombre,
      })
    })

    return temp
  }

  const getAuditorias = () => {
    HttpAxios.post('/entidad/estados', {
      id: 33,
      idestadopadre: 0,
      todos: false,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setCheckAuditorias(options)
        if (payload68) {
          const elem = options.filter((el: any) => el.value === payload68?.idsector)
          if (elem) {
            onChangeCheckAuditoria(elem[0])
          }
          searchByBack()
        }
        changePayload68(null)
      })
      .catch(() => {
        changePayload68(null)
      })
  }

  const getJefes = () => {
    HttpAxios.post('/zonas/jefes', {
      todos: true,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setCheckjefes(options)
        if (payload68) {
          const elem = options.filter((el: any) => el.value === payload68?.idjefezonal)
          if (elem) {
            onChangeCheckJefe(elem[0])
          }
        }
        getAuditorias()
      })
      .catch((err) => {
        getAuditorias()
      })
  }

  const handleExport = () => {
    changeIsLoading(true)

    HttpAxios.post('/auditoria/evaluacion_anual_web_xls', parameters)
      .then((res: any) => {
        const { err, url, response } = res?.data || {}
        changeIsLoading(false)

        if (err) {
          changeSnackBar(EErrorMsg.generic)
        } else {
          handleDownload(url || response)
        }
      })
      .catch(() => {
        changeIsLoading(false)
        changeSnackBar(EErrorMsg.generic)
      })
  }

  const handleDownload = (urlFile: string = '') => {
    const link = document.createElement('a')
    link.href = urlFile
    link.setAttribute('download', `${urlFile.split('/')[urlFile.split('/').length - 1]}`)
    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      link?.parentNode?.removeChild(link)
    }, 100)
  }

  const search = (page: number = payload68?.page || 0, isPaginated?: boolean) => {
    if (!isPaginated) {
      const stores = store?.map((item) => {
        return item.value
      })
      params = {
        idtienda: stores ? [...stores] : [0],
        idjefezonal: jefe?.value || 0,
        idtipoauditoria: auditoria?.value || 316,
        aniobase: year?.value || 2022,
      }
      memoParams = params
    }

    let paramsSearch = params
    changeIsLoading(true)
    setInitLoading(true)
    setMsgError('')
    setRows([])

    HttpAxios.post('/auditoria/evaluacion_anual_web', paramsSearch)
      .then((res: any) => {
        const data = res?.data || {}
        const response = data.response || []
        if (response.length) {
          const { nextpage = 2, page = 1, total_pages = 0, total_rows = 0 } = data
          setNoData(false)
          setColumns(headerColumns)
          setRows(response)
          setTotalPerPage(total_rows || response.length)
          setActivePage(page)
          setTotalPages(total_pages)
          setNextPage(nextpage)
          setParameters(memoParams)
          setMsgError('')
        } else {
          setNoData(true)
          setRows([])
        }

        changeIsLoading(false)
        setInitLoading(false)
      })
      .catch(() => {
        setMsgError(Errors.generic)
        changeIsLoading(false)
        setInitLoading(false)
      })
  }

  useEffect(() => {
    const chartTemp = { ...chart }
    chartTemp.options = {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1],
      },
      colors: ['#4f81bd', '#9bbb59', '#506621'],
      title: {
        text: 'Comparativo de Resultado de Auditorias Operativas Anuales',
        offsetX: 110,
      },
      xaxis: {
        categories: rows.map((item: any) => {
          return item.tiendasincodigo
        }),
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#000',
          },
          labels: {
            style: {
              colors: '#000',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetY: 30,
          offsetX: 60,
        },
      },
    }
    chartTemp.series = [
      {
        name: `Resultado ${year?.value - 1}`,
        type: 'column',
        data: rows.map((item: any) => {
          return item.anioanterior || 0
        }),
      },
      {
        name: `Resultado ${year?.value}`,
        type: 'column',
        data: rows.map((item: any) => {
          return item.aniobase || 0
        }),
      },
      {
        name: 'Variación',
        type: 'column',
        data: rows.map((item: any) => {
          return item.variacion || 0
        }),
      },
    ]
    setChart(chartTemp)
  }, [rows])

  const handlePaged = (ind: number) => {
    search(ind, true)
  }

  const onChangeCheckAuditoria = (evt: any) => {
    setAuditoria(evt)
  }

  const onChangeCheckAnioBase = (evt: any) => {
    setYear(evt)
  }

  const onChangeCheckJefe = (evt: any) => {
    setJefe(evt)
  }

  const onChangeStores = (evt: any) => {
    setStore(evt)
  }

  const initBackValues = (stores: Array<ITiendas> | null) => {
    if (payload68) {
      const elem: any = (stores || []).filter((el: any) => el.id === payload68?.idtienda)
      if (elem) {
        const el = getItems(elem)
        onChangeStores(el.length === 1 ? el[0] : el[1])
      }
    }
  }

  const searchByBack = () => {
    if (payload68 && lists && btnSearchRef) {
      btnSearchRef.current.click()
    }
  }

  const openModalEdit = (data: any, r: any) => {
    setRowSelected(JSON.parse(JSON.stringify(data)))
    setStatusSelected(JSON.parse(JSON.stringify(status)))
    setIdxSelected(JSON.parse(JSON.stringify(r)))
    changeShowModalEdit68(true)
  }

  const rowClick = (obj: any, r: any): void => {
    changePayload68(memoParams)
    const url = '/detalle/submodulo/68/' + obj?.idcontrato
    history.push(url)
  }

  const newContrato = () => {
    changePayload68(memoParams)
    const url = '/detalle/submodulo/68/0'
    history.push(url)
  }

  const getOptionsYear = () => {
    let years = []
    const currentYear = new Date().getFullYear()
    years = [...Array.from({ length: currentYear + 1 - (currentYear - 4) }, (_: any, i: number) => i + (currentYear - 4))]
    years = years.map((item: any) => {
      return { value: item, label: item }
    })
    setYears(years)
  }

  useEffect(() => {
    const stores: Array<ITiendas> | null = useMe?.tiendas || null
    setStores(stores)
    initBackValues(stores)
    getJefes()
    !payload68 && search()
    getOptionsYear()
    changeUrlBackTop('/submodulo/68')
    changeIsLoading(true)
  }, [])

  return (
    <>
      <div className="View68Search">
        <div className="row-6">
          <div className="col">
            <label className="label">Tienda</label>
            <Select
              id="store"
              name="store"
              styles={selectStyles}
              theme={selectTheme}
              value={store}
              isMulti={true}
              hideSelectedOptions={false}
              onChange={(evt) => onChangeStores(evt)}
              placeholder="Selecciona una tienda"
              options={getItems(stores)}
              components={{ ValueContainer }}
            />
          </div>
          <div className="col">
            <label className="label">Jefe Zonal</label>
            <Select id="jefes" name="jefes" styles={selectStyles} theme={selectTheme} value={jefe} onChange={(evt) => onChangeCheckJefe(evt)} placeholder="Selecciona un jefe" options={checkjefes} />
          </div>
          <div className="col">
            <label className="label">Tipo Auditoría</label>
            <Select id="zonas" name="zonas" styles={selectStyles} theme={selectTheme} value={auditoria} onChange={(evt) => onChangeCheckAuditoria(evt)} placeholder="Selecciona una zona" options={checkauditorias} />
          </div>
          <div className="col">
            <label className="label">Año Base</label>
            <Select id="zonas" name="zonas" styles={selectStyles} theme={selectTheme} value={year} onChange={(evt) => onChangeCheckAnioBase(evt)} placeholder="Selecciona una zona" options={years} />
          </div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button type="button" onClick={() => search()} className="button buttonFull" ref={btnSearchRef}>
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div className="View68Body">
        {noData || msgError ? (
          !initLoading && (
            <div
              className={classnames({
                noData: noData,
                msgError: msgError,
              })}
            >
              {msgError ? msgError : 'No se encontró resultado en la búsqueda'}
            </div>
          )
        ) : (
          <>
            <div className="View68SearchActions">
              <div className="View68SearchActionsTotal">{totalPerPage > 0 && 'Total de registros: ' + totalPerPage}</div>
              <div className="separate"></div>
              {totalPerPage > 0 && (
                <button className="button buttonExcel" type="button" onClick={handleExport}>
                  <img src={IconExcel} alt="Excel" className="buttonIconLeft" />
                  Exportar
                </button>
              )}
            </div>
            <ReactDataGrid
              className="View68Table"
              columns={columns}
              rows={rows}
              onRowsChange={setRows}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
            />
            <TablePaged msgError={msgError} activePage={activePage} handlePaged={handlePaged} totalPages={totalPages} />
            <div>
              <ApexChart options={chart.options} series={chart.series} type="line" width="100%" height={350} />
            </div>
          </>
        )}
      </div>
    </>
  )
}

const ValueContainer = ({ children, ...props }: any) => {
  let [values, input] = children
  if (Array.isArray(values)) {
    const val = (i: any) => values[i].props.children
    const { length } = values
    switch (length) {
      case 1:
        values = `${val(0)} `
        break
      default:
        const otherCount = length - 1
        values = `${val(0)}+ ${otherCount} `
        break
    }
  }
  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  )
}

export default View68
