import { FunctionComponent } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useUserMe } from '../../hooks/userme'

interface IUnProtectedRoute {
  component: any
  path: string
}

const UnProtectedRoute: FunctionComponent<IUnProtectedRoute> = ({ component: Component, ...restProps }) => {
  const [userMe] = useUserMe()
  const accessToken = userMe ? userMe.token : ''
  const isLoggedIn = !!accessToken

  return (
    <Route
      {...restProps}
      render={(props) => {
        return isLoggedIn ? (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: props.location,
              },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }}
    />
  )
}

export default UnProtectedRoute
