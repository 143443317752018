import { useContext, useEffect, useState, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useUserMe } from '../../commons/hooks/userme'
import { AppContext } from '../../commons/context/App-context'
import DatePicker, { registerLocale } from 'react-datepicker'
import ModalPhoto from '../../commons/components/modal/modalPhoto'
import Footer from '../../commons/components/footer/Footer'
import Header from '../../commons/components/header/Header'
import Breadcrumb from '../../commons/components/breadcrumb/Breadcrumb'
import HttpAxios from '../../commons/hooks/http'
import useGeo from '../../commons/hooks/geo'
import SignatureCanvas from 'react-signature-canvas';
import es from 'date-fns/locale/es'
import dayjs from 'dayjs'
import Amplify from 'aws-amplify'
import environment from '../../environments/environment'
import AWS from 'aws-sdk';
import 'dayjs/locale/es'
import './index.scss'

Amplify.configure(environment.awsAmplify)

AWS.config.update({
  accessKeyId: 'AKIARAMW7IP2RB2WF43Y',
  secretAccessKey: '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',
  region: 'us-east-1'
});

registerLocale('es', es)

const ESign = () => {
  const { changeSnackBar, changeDataModalPhoto, changeShowModalPhoto } = useContext(AppContext)
  const [useMe] = useUserMe()
  const history = useHistory()
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true)
  const query = location.search;
  const paramValue = query ? query.substring(1) : null;

  const subsDays = dayjs(new Date())
  const [breadcrumb, setBreadcrumb] = useState<string>('')
  const [fecha, setFecha] = useState(subsDays.toDate())
  const [nomCajero, setNomCajero] = useState('')
  const [tipoCambio, setTipoCambio] = useState('')
  const [supervisor, setSupervisor] = useState('')
  const [caja, setCaja] = useState('')
  const [turno, setTurno] = useState('')
  const [totalVentaDia, setTotalVentaDia] = useState('')
  const [totalCuadrado, setTotalCuadrado] = useState('')
  const [totalTeorico, setTotalTeorico] = useState('')
  const [totalFisico, setTotalFisico] = useState('')
  const [diferencia, setDiferencia] = useState('')
  const [observaciones, setObservaciones] = useState('')
  const [flagDescuentoFirmado, setFlagDescuentoFirmado] = useState(false)
  const [msgError, setMsgError] = useState<string>('')
  const [images, setImages] = useState<Array<any>>([])
  const [idcheckevento, setIdcheckevento] = useState<number>(0)
  const { latitude, altitude, longitude } = useGeo()
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const [cbv, setCbv] = useState<boolean>(false)

  const getIdByCuadraturaKey = async (cuadraturakey: string | null) => {
    await HttpAxios.post('/cuadratura/getid_bycuadraturakey', { cuadraturakey }).then(({ data }) => {
      const [ cuadratura ] = data?.response || [];

      if (cuadratura?.usuariocajero === useMe?.info?.user?.username) {
        localStorage.setItem('cuadraturaSuccess', 'true');

        const idcuadratura = cuadratura?.idcuadratura || 91719;

        setIdcheckevento(idcuadratura);
        getIncidencia(idcuadratura)
        listarAlbum(idcuadratura)

        getAperturaLinkDscto(idcuadratura);
        localStorage.removeItem('redirectAfterLogin')
        localStorage.setItem('cuadraturaSuccess', 'false');
      } else {
        localStorage.setItem('cuadraturaMessage', 'true');
        history.push('/')
      }
      setLoading(false)
    })
    .catch((e: any) => {
      localStorage.setItem('cuadraturaSuccess', 'false');

      console.log(e)
    })
  };

  const getIncidencia = async (idcheckevento: number | string) => {
    const payload = {
      idcuadratura: idcheckevento,
    }

    await HttpAxios.post('/cuadratura/listagrupo', payload)
      .then((res: any) => {
        const data = res?.data?.response || []
        if (data) {
          const { cabecera } = data

          setBreadcrumb(cabecera?.codigointerno || '')

          const isCBV = /CBV/i.test(cabecera?.codigointerno);
          setCbv(isCBV);

          setFecha(new Date(cabecera?.fecha + 'T00:00'))
          setNomCajero(cabecera?.cajeronombre || '')
          setTipoCambio(cabecera?.tipocambio || '')
          setSupervisor(cabecera?.supervisor || '')
          setCaja(cabecera?.caja || '')
          setTurno(cabecera?.turno || '')
          setTotalVentaDia(cabecera?.totalventadia || '')
          setTotalCuadrado(cabecera?.totalcuadrado || '')
          setTotalTeorico(cabecera?.totalteorico || '')
          setTotalFisico(cabecera?.totalfisico || '')
          setDiferencia(cabecera?.diferencia || '')
          setObservaciones(cabecera?.observaciones || '')
          setFlagDescuentoFirmado(cabecera?.flagdescuentofirmado)
          setInitLoading(true)

          if (data?.detalle) {
            setMsgError('')
          }
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const listarAlbum = async (idcheckevento: any) => {
    const params = {
      identidad: 19,
      pkentidad: idcheckevento,
    }
    HttpAxios.post('/albumfoto/listar', params)
      .then((res: any) => {
        const data = res?.data?.response || []

        setImages(data)
      })
      .catch((e: any) => {
        console.log(e)
      })
  }

  const onChangeFecha = (evt: any) => {
    setFecha(evt)
  }

  const uploadFile = async (files: any, identidad: any, pkentidad: any) => {
    let filesS3: any = []

    const s3 = new AWS.S3();

    try {
      files.map(async (file: any) => {
        const params = {
          Bucket: 'casaideas.files',
          Key: file?.file?.name,
          Body: file?.file,
          ContentType: file?.file?.type
        };

        await s3.upload(params).promise();

        const signedUrl = await s3.getSignedUrl('getObject', {
          Bucket: 'casaideas.files',
          Key: file?.file?.name,
          Expires: 3600
        });

        filesS3.push(signedUrl)

        handleUploadSignature(filesS3, identidad, pkentidad)
      });

    } catch (error) {
      console.error("Error al subir el archivo:", error);
    }
  }

  const handleUploadSignature = async (filesS3: any, identidad: any, pkentidad: any) => {
    const params = {
      coordenadas: '',
      identidad,
      pkentidad,
      urls: filesS3
    }

    HttpAxios.post('/albumfoto/crearmultiple', params)
      .then((res: any) => {
        const urlfirmadescuento = res.data.response[0].url;
        handleUpdateSignature(pkentidad, urlfirmadescuento)
      })
      .catch((e: any) => {
        console.log(e)
      })
  }

  const handleUpdateSignature = async (idcuadratura: number, url: string) => {
    const usuario = useMe?.info?.user?.username;
    const coordenadas = (latitude || '') + ',' + (altitude || longitude || '');
    const urlfirmadescuento = url.split('?')[0];
    const params = {
      idcuadratura,
      usuario,
      urlfirmadescuento,
      coordenadas
    }

    HttpAxios.post('/cuadratura/actualizafirmadescuento', params)
      .then((res: any) => {
        changeSnackBar('firma actualizada correctamente')
        setFlagDescuentoFirmado(true);
        setIsLoadingEsign(false);
      })
      .catch((e: any) => {
        console.log(e)
      })
  }

  const getAperturaLinkDscto = async (idcuadratura: number) => {
    const usuario = useMe?.info?.user?.username;
    const coordenadas = (latitude || '') + ',' + (altitude || longitude || '');

    await HttpAxios.post('/cuadratura/aperturalink_descuento', { idcuadratura, usuario, coordenadas  }).then(({ data }) => {

    })
    .catch((e: any) => {
      console.log(e)
    })
  };

  const sigCanvas = useRef<SignatureCanvas | null>(null);

  const dataURLToFile = (dataURL: string, filename: string): File | null => {
    const arr = dataURL.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);

    if (!mimeMatch) {
      console.error('MIME type not found in dataURL');
      return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const [isLoadingEsign, setIsLoadingEsign] = useState(false);
  const saveSignature = (format: 'png' | 'jpg') => {
    if (sigCanvas.current) {
      const now = new Date();
      const year = String(now.getFullYear()).slice(-2);
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const fileName = `${idcheckevento}_${year}${month}${day}_${hours}${minutes}${seconds}.${format}`;
      const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL(`image/${format}`);
      const file = dataURLToFile(dataURL, `${fileName}`);

      setIsLoadingEsign(true);
      uploadFile([{ file }], 28, idcheckevento);
    }
  };

  const clearSignature = () => {
    sigCanvas.current?.clear();
  };

  const clickModalPhoto = (photo: string, evidences: Array<any>, ind: number) => {
    changeDataModalPhoto({ photo: '', photos: [] })
    const newPhotos = evidences.map((p) => p.url)
    changeDataModalPhoto({ photo, photos: newPhotos, initIndex: ind })
    changeShowModalPhoto(true)
  }

  useEffect(() => {
    if (paramValue) {
      getIdByCuadraturaKey(paramValue)
    } else {
      localStorage.setItem('cuadraturaMessage', 'false');
      history.push('/')
    }
  }, [paramValue])

  return (
    <>
      <div className="ContentFull">
        <Header></Header>

        <div className="Content">

          <Breadcrumb text={breadcrumb} urlBack={'/'}></Breadcrumb>

          {loading && <div className="ContentInner">... validando</div>}

          {!loading && initLoading && <>
            <div className="ContentInner">
              <div className="DetailView72Body">
                <div className="DetailView72Data">
                  <div className="row-2">
                    <div className="col">
                      <label className="label">Fecha</label>
                      <DatePicker className="input" selected={fecha} selectsRange={false} onChange={onChangeFecha} locale="es" dateFormat="dd/MM/yyyy" placeholderText="Selecione un rango de fecha" readOnly />
                    </div>
                    <div className="col">
                      <label className="label">Cajero</label>
                      <input className="input" value={nomCajero} readOnly />
                    </div>
                    <div className="col">
                      <label className="label">Tipo Cambio</label>
                      <input className="input text-right" value={tipoCambio} readOnly />
                    </div>
                  </div>
                  <div className="row-2">
                    <div className="col">
                      <label className="label">Supervisor</label>
                      <input className="input" value={supervisor} readOnly />
                    </div>
                    <div className="col">
                      <label className="label">Caja</label>
                      <input className="input" value={caja} readOnly />
                    </div>
                    <div className="col">
                      <label className="label">Turno</label>
                      <input className="input" value={turno} readOnly />
                    </div>
                  </div>
                  <div className="row-2">
                    <div className="col">
                      <label className="label">{!cbv ? 'Total Teórico S/.' : 'Diferencia S/.'}</label>
                      <input className="input text-right" value={!cbv ? totalTeorico : totalVentaDia} readOnly />
                    </div>
                    <div className="col">
                      <label className="label">{!cbv ? 'Total Físico S/.' : 'Diferencia US$'}</label>
                      <input className="input text-right" value={!cbv ? totalFisico : totalCuadrado} readOnly />
                    </div>
                    <div className="col">
                      <label className="label">{!cbv ? 'Diferencia' : 'Diferencia Giftcard'}</label>
                      <input className="input text-right" value={diferencia} readOnly />
                    </div>
                  </div>
                  <div className="row-2">
                    <div className="col">
                      <label className="label">Observaciones</label>
                      <textarea className="input" readOnly value={observaciones}></textarea>
                    </div>
                  </div>
                  <div className="row-2">
                    <div className="col">
                      <label className="label">Evidencia</label>
                      <div className="listEvidencia">
                        <div className="listEvidencia__items">
                          {images.map((image, index) => (
                            <div key={index} className="listEvidencia__item">
                              <figure className="listEvidencia__wrapImage" onClick={() => clickModalPhoto(image.url, images, index)}>
                                <img src={image.url} alt="" />
                              </figure>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {!flagDescuentoFirmado && <>
                    <div className="row-2">
                      <div className="col">
                        <label className="label">Firma Electrónica</label>
                        <SignatureCanvas
                          penColor="black"
                          canvasProps={{ className: 'sigCanvas' }}
                          ref={sigCanvas}
                        />
                      </div>
                    </div>
                    <div className="row-2">
                        <div className="col">
                        <button type="button" className="button button-red" onClick={() => saveSignature('png')} disabled={isLoadingEsign} >
                          {isLoadingEsign ? (
                            <><span className="loader"></span> Procesando</>
                          ) : (
                            'Aceptar y Guardar'
                          )}
                        </button>
                        <button type="button" className="button button-gray" onClick={clearSignature} disabled={isLoadingEsign}>
                          Limpiar
                        </button>
                      </div>
                    </div>
                  </>}

                  {flagDescuentoFirmado && <>
                    <div className="row-2">
                      <div className="col sigCanvas-success">Firma ya generada</div>
                    </div>
                    <div className="row-2">
                      <div className="col">
                        <button type="button" className="button button-continue" onClick={() => history.push('/')}>Continuar</button>
                      </div>
                    </div>
                  </>}
                </div>
              </div>
            </div>
          </>}

          {msgError && <div className="row error msgError">{msgError}</div>}
        </div>

        <Footer></Footer>

      </div>

      <ModalPhoto></ModalPhoto>
    </>
  )
}

export default ESign
