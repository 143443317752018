import { useContext, useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactDataGrid from 'react-data-grid'
import classnames from 'classnames'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import HttpAxios from '../../../commons/hooks/http'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import { EErrorMsg } from '../../../commons/statics/controls'
import TableActions from '../../../commons/components/table/TableActions'
import TablePaged from '../../../commons/components/table/TablePaged'
import { useUserMe } from '../../../commons/hooks/userme'
import { AppContext } from '../../../commons/context/App-context'
import { ESelectCustom, ESelectTheme } from '../../../commons/statics/controls'
import IconPdf from '../../../images/pdf.svg'
import IconExcel from '../../../images/excel.svg'
import { ITiendas } from '../../../commons/interfaces/user'
import environment from '../../../environments/environment'
import { Errors } from '../../../commons/constants/submodules'
import { IParameters } from '../../../commons/interfaces/items'
import { currencyFormat, currencyFormat3Dig } from '../../../commons/utils/utils'
import es from 'date-fns/locale/es'
import './view64.scss'

registerLocale('es', es)

const defaultOption = {
  value: 0,
  label: 'TODOS',
}
let params: IParameters = {}
let memoParams: IParameters = {}

function View64() {
  const { changeIsLoading, changeSnackBar, changeUrlBackTop, changePayload64, payload64 } = useContext(AppContext)
  const history = useHistory()
  const [noData, setNoData] = useState<boolean>(false)
  const [msgError, setMsgError] = useState<string>('')
  const [useMe, setUseMe] = useUserMe()
  const [stores, setStores] = useState<Array<ITiendas> | null>(null)
  const [checklists, setChecklists] = useState([])
  const [status, setStatus] = useState([])
  const [lists, setLists] = useState({ value: 21, label: 'Checklist Revisión Visual Tienda' })
  const [stateAttention, setStateAttention] = useState(defaultOption)
  const [store, setStore] = useState(defaultOption)
  const [rows, setRows] = useState<Array<any>>([])
  const [parameters, setParameters] = useState<IParameters>({})
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const selectStyles = ESelectCustom
  const selectTheme = ESelectTheme
  const [endDate, setEndDate] = useState(new Date())
  const subsDays = dayjs(new Date()).subtract(40, 'day')
  const [startDate, setStartDate] = useState(subsDays.toDate())
  const [fullName, setFullName] = useState('')
  const btnSearchRef = useRef<any>(null)

  const [recordsMonths, setRecordsMonths] = useState({})
  const [coberturas, setCoberturas] = useState<Array<any>>([])
  const [headerDates, setHeaderDates] = useState<any>({})
  const [manyDays, setManyDays] = useState(0)
  const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

  const getItems = (items: any, hasDefault?: boolean): any => {
    let temp: any = hasDefault ? [] : [defaultOption]

    items?.forEach((s: any) => {
      temp.push({
        value: s.id,
        label: s.descripcion,
      })
    })

    return temp
  }

  const getTypes = () => {
    HttpAxios.post('/checklists', {
      idmodulo: 22,
      todos: false,
      identidad: 0,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setChecklists(options)
        if (payload64) {
          const elem = options.filter((el: any) => el.value === payload64?.idcuadratura)
          if (elem) {
            onChangeChecklists(elem[0])
          }
          searchByBack()
        }
        changePayload64(null)
      })
      .catch((err) => {
        changePayload64(null)
      })
  }

  const getStatus = () => {
    HttpAxios.post('/entidad/estados', {
      id: 19,
      idestadopadre: 0,
      todos: true,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setStatus(options)
        if (payload64) {
          const elem = options.filter((el: any) => el.value === payload64?.idestado)
          if (elem) {
            onChangeStateAtention(elem[0])
          }
        }
        getTypes()
      })
      .catch((err) => {
        getTypes()
      })
  }

  const handleExport = () => {
    changeIsLoading(true)

    HttpAxios.post('/checklists/visual_resumenweb_xls', parameters)
      .then((res: any) => {
        const { err, url, response } = res?.data || {}
        changeIsLoading(false)

        if (err) {
          changeSnackBar(EErrorMsg.generic)
        } else {
          handleDownload(url || response)
        }
      })
      .catch(() => {
        changeIsLoading(false)
        changeSnackBar(EErrorMsg.generic)
      })
  }

  const handleDownload = (urlFile: string = '') => {
    const link = document.createElement('a')
    link.href = urlFile
    link.setAttribute('download', `${urlFile.split('/')[urlFile.split('/').length - 1]}`)
    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      link?.parentNode?.removeChild(link)
    }, 100)
  }

  const search = (page: number = payload64?.page || 1, isPaginated?: boolean) => {
    if (!isPaginated) {
      const rangeStart = startDate ? dayjs(startDate).format(environment.search.formatDate) : ''
      const rangeEnd = endDate ? dayjs('' + endDate).format(environment.search.formatDate) : ''

      params = {
        fecha_ini: rangeStart,
        fecha_fin: rangeEnd || rangeStart,
        idcheck: lists?.value || 21,
      }
      memoParams = params
    }

    let paramsSearch = params
    paramsSearch.page = page
    changeIsLoading(true)
    setInitLoading(true)
    setMsgError('')
    setRows([])
    HttpAxios.post('/checklists/visual_resumenweb', paramsSearch)
      .then((res: any) => {
        const data = res?.data || {}
        const response = data.response || []
        if (response.length) {
          setRows(response)
          let records = groupByProperties(response, ['ubicacion', 'tienda', 'anio', 'nummes', 'numdia', 'porcentajeevaluacion', 'color'])

          const coberturas = Object.keys(records).map(function (cobertura: any) {
            let keyOf = records[cobertura as keyof typeof records]
            return {
              sizeKey: Object.entries(keyOf).length,
              cobertura: cobertura,
              tiendas: Object.entries(records[cobertura as keyof typeof records]).map((tienda: any) => {
                return {
                  tienda: tienda[0],
                  years: Object.entries(tienda[1]).map((year: any) => {
                    return {
                      year: year[0],
                      months: Object.entries(year[1]).map((month: any) => {
                        return {
                          month: month[0],
                          days: Object.entries(month[1]).map((day: any) => {
                            return {
                              day: day[0],
                              percentages: Object.entries(day[1]).map((percentage: any) => {
                                return {
                                  percentage: percentage[0],
                                  color: percentage[1][0],
                                }
                              }),
                            }
                          }),
                        }
                      }),
                    }
                  }),
                }
              }),
            }
          })

          const dateArr = getDateArray(startDate, endDate)
          let manyDays = 0
          let res = {}
          let fn = (year: any, month: any, o: any = res) => {
            o[year][parseInt(month)] = dateArr.reduce((acum: any, date: any) => {
              let d = new Date(date).toISOString().split('T')[0]
              if (`${year}-${month}` === d.slice(0, 7)) {
                acum.push(parseInt(d.slice(8, 10)))
                manyDays = manyDays + 1
              }
              return acum
            }, [])
          }
          dateArr.forEach((date: any) => {
            let [year, month] = new Date(date).toISOString().split('T')[0].split('-')
            if (!res[year as keyof typeof res]) res[year as keyof typeof res] = {} as never
            fn(year, month)
          })

          setRecordsMonths(records)
          setCoberturas(coberturas)
          setHeaderDates(res)
          setManyDays(manyDays)
          setParameters(memoParams)

          setNoData(false)
          setMsgError('')
        } else {
          setNoData(true)
          setRows([])
        }

        changeIsLoading(false)
        setInitLoading(false)
      })
      .catch(() => {
        setMsgError(Errors.generic)
        changeIsLoading(false)
        setInitLoading(false)
      })
  }

  const getDateArray = function (start: any, end: any) {
    const arr = []
    const dt = new Date(start)
    while (dt <= end) {
      arr.push(new Date(dt))
      dt.setDate(dt.getDate() + 1)
    }
    return arr
  }

  const groupBy = (list: any, prop: any) => {
    return list.reduce((groupped: any, item: any) => {
      const key = item[prop]
      delete item[prop]
      if (groupped.hasOwnProperty(key)) {
        groupped[key].push(item)
      } else {
        groupped[key] = [item]
      }
      return groupped
    }, {})
  }

  const groupSubKeys = (obj: any, properties: any, propIndex: any) => {
    const grouppedObj = groupBy(obj, properties[propIndex])
    Object.keys(grouppedObj).forEach((key) => {
      if (propIndex < properties.length - 2) {
        grouppedObj[key] = groupSubKeys(grouppedObj[key], properties, propIndex + 1)
      } else {
        grouppedObj[key] = grouppedObj[key].map((item: any) => item[properties[propIndex + 1]])
      }
    })
    return grouppedObj
  }

  const groupByProperties = (list: any, properties: any) => {
    return groupSubKeys(list, properties, 0)
  }

  const handlePaged = (ind: number) => {
    search(ind, true)
  }

  const onChangeChecklists = (evt: any) => {
    setLists(evt)
  }

  const onChangeStateAtention = (evt: any) => {
    setStateAttention(evt)
  }

  const onChangeStores = (evt: any) => {
    setStore(evt)
  }

  const onChangeRange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const initBackValues = (stores: Array<ITiendas> | null) => {
    if (payload64) {
      const { fecha_ini, fecha_fin } = payload64
      const y: number = Number(fecha_ini.substring(0, 4))
      const m: number = Number(fecha_ini.substring(4, 6)) - 1
      const d: number = Number(fecha_ini.substring(6, 8))
      const yEnd: number = Number(fecha_fin.substring(0, 4))
      const mEnd: number = Number(fecha_fin.substring(4, 6)) - 1
      const dEnd: number = Number(fecha_fin.substring(6, 8))

      setStartDate(new Date(y, m, d))
      setEndDate(new Date(yEnd, mEnd, dEnd))

      const elem: any = (stores || []).filter((el: any) => el.id === payload64?.idtienda)
      if (elem) {
        const el = getItems(elem)
        onChangeStores(el.length === 1 ? el[0] : el[1])
      }

      setFullName(payload64?.supervisor! || '')
    }
  }

  const searchByBack = () => {
    if (payload64 && lists && btnSearchRef) {
      btnSearchRef.current.click()
    }
  }

  useEffect(() => {
    const stores: Array<ITiendas> | null = useMe?.tiendas || null
    setStores(stores)
    initBackValues(stores)
    getStatus()
    !payload64 && search()
    changeUrlBackTop('/submodulo/64')
    changeIsLoading(true)
  }, [])

  return (
    <>
      <div className="View64Search">
        <div className="row-6">
          <div className="col">
            <label className="label">Tipo CheckList</label>
            <Select id="lists" name="lists" styles={selectStyles} theme={selectTheme} value={lists} onChange={(evt) => onChangeChecklists(evt)} placeholder="Selecciona un tipo" options={checklists} />
          </div>
          <div className="col">
            <label className="label">Rango de fechas</label>
            <DatePicker
              selected={startDate}
              onChange={onChangeRange}
              startDate={startDate}
              endDate={endDate}
              selectsRange={true}
              locale="es"
              className="input"
              dateFormat="dd/MM/yyyy"
              placeholderText="Selecione un rango de fecha"
              maxDate={new Date()}
            />
          </div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button type="button" onClick={() => search()} className="button buttonFull" ref={btnSearchRef}>
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div className="View64Body">
        {noData || msgError ? (
          !initLoading && (
            <div
              className={classnames({
                noData: noData,
                msgError: msgError,
              })}
            >
              {msgError ? msgError : 'No se encontró resultado en la búsqueda'}
            </div>
          )
        ) : (
          <>
            <div className="View64SearchActions">
              <div className="separate"></div>
              {
                <button className="button buttonExcel" type="button" onClick={handleExport}>
                  <img src={IconExcel} alt="Excel" className="buttonIconLeft" />
                  Exportar
                </button>
              }
            </div>
            {!!manyDays && (
              <>
                <div className="table__body">
                  <div className="table__body__days">
                    <table>
                      <tbody>
                        <tr>
                          <td colSpan={2 + manyDays}>
                            <div className="bg-gray text-main color-black">{!!rows.length && rows[0].titulo}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="cobertura bg-gray"></div>
                          </td>
                          <td>
                            <div className="tienda bg-gray"></div>
                          </td>
                          {Object.keys(headerDates).map((_year: any, yearIndex: number) => {
                            return Object.entries(headerDates)[yearIndex].map((item: any) => {
                              if (isNaN(item)) {
                                return Object.entries(item).map((subItem: any) => {
                                  return (
                                    <td colSpan={subItem[1].length}>
                                      <div className="bg-gray text-center color-black justify-center">{meses[subItem[0] - 1]}</div>
                                    </td>
                                  )
                                })
                              }
                            })
                          })}
                        </tr>
                        <tr>
                          <td>
                            <div className="cobertura bg-gray color-red">Cobertura</div>
                          </td>
                          <td>
                            <div className="tienda bg-gray color-red justify-center">Tienda</div>
                          </td>
                          {Object.keys(headerDates).map((_year: any, yearIndex: number) => {
                            return Object.entries(headerDates)[yearIndex].map((item: any) => {
                              if (isNaN(item)) {
                                return Object.entries(item).map((subItem: any) => {
                                  return subItem[1].map((day: any) => {
                                    return (
                                      <td>
                                        <div className="day bg-gray text-center color-black justify-center">{day}</div>
                                      </td>
                                    )
                                  })
                                })
                              }
                            })
                          })}
                        </tr>
                        {coberturas.map((cobertura: any) => {
                          return (
                            <>
                              <tr>
                                <td rowSpan={cobertura.sizeKey + 1}>
                                  <div className="cobertura color-black">{cobertura.cobertura}</div>
                                </td>
                              </tr>
                              {cobertura.tiendas.map((tienda: any, indexTienda: number) => {
                                return (
                                  <tr>
                                    <td>
                                      <div className="tienda color-black">{tienda.tienda}</div>
                                    </td>
                                    {Object.keys(headerDates).map((_year: any, yearIndex: number) => {
                                      // eslint-disable-next-line array-callback-return
                                      return Object.entries(headerDates)[yearIndex].map((item: any) => {
                                        if (isNaN(item)) {
                                          return Object.entries(item).map((_month: any) => {
                                            // eslint-disable-next-line array-callback-return
                                            return _month[1].map((_day: any) => {
                                              if (parseInt(cobertura.tiendas[indexTienda].years[0].year) === parseInt(_year)) {
                                                // eslint-disable-next-line array-callback-return
                                                let findMonth = cobertura.tiendas[indexTienda].years[0].months.find((o: any) => parseInt(o.month) === parseInt(_month[0]))
                                                if (findMonth) {
                                                  // eslint-disable-next-line array-callback-return
                                                  return cobertura.tiendas[indexTienda].years[0].months.map((month: any) => {
                                                    if (parseInt(month.month) === parseInt(_month[0])) {
                                                      let findDay = month.days.find((o: any) => parseInt(o.day) === parseInt(_day))
                                                      if (findDay) {
                                                        return findDay.percentages.map((percentage: any) => {
                                                          return (
                                                            <td>
                                                              <div className="day" style={{ color: percentage.color }}>
                                                                {percentage.percentage}
                                                              </div>
                                                            </td>
                                                          )
                                                        })
                                                      } else {
                                                        return (
                                                          <td>
                                                            <div className="day">-</div>
                                                          </td>
                                                        )
                                                      }
                                                    }
                                                  })
                                                } else {
                                                  return (
                                                    <td>
                                                      <div className="day">-</div>
                                                    </td>
                                                  )
                                                }
                                              }
                                            })
                                          })
                                        }
                                      })
                                    })}
                                  </tr>
                                )
                              })}
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default View64
