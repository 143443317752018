import { useContext, useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactDataGrid from 'react-data-grid'
import classnames from 'classnames'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import HttpAxios from '../../../commons/hooks/http'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import { EErrorMsg } from '../../../commons/statics/controls'
import TableActions from '../../../commons/components/table/TableActions'
import TablePaged from '../../../commons/components/table/TablePaged'
import { useUserMe } from '../../../commons/hooks/userme'
import { AppContext } from '../../../commons/context/App-context'
import { ESelectCustom, ESelectTheme } from '../../../commons/statics/controls'
import IconPdf from '../../../images/pdf.svg'
import IconExcel from '../../../images/excel.svg'
import { ITiendas } from '../../../commons/interfaces/user'
import environment from '../../../environments/environment'
import { Errors } from '../../../commons/constants/submodules'
import { IParameters } from '../../../commons/interfaces/items'
import { currencyFormat, currencyFormat3Dig } from '../../../commons/utils/utils'
import es from 'date-fns/locale/es'
import './view60.scss'

registerLocale('es', es)

const defaultOption = {
  value: 0,
  label: 'TODOS',
}
let params: IParameters = {}
let memoParams: IParameters = {}

function View60() {
  const { changeIsLoading, changeSnackBar, changeUrlBackTop, changePayload60, payload60 } = useContext(AppContext)
  const history = useHistory()
  const [noData, setNoData] = useState<boolean>(false)
  const compPDF = (data: any) => {
    return data?.row?.urlformato ? (
      <a href={data.row.urlformato} target="blank">
        <img className="View60Pdf" src={IconPdf} alt="PDF" />
      </a>
    ) : (
      <></>
    )
  }
  const compPercentage = (data: any, porcentajeminimo: any) => {
    return parseInt(data) < parseInt(porcentajeminimo) ? (
      <span style={{ color: '#CC0000' }} title={`porcentaje mínimo es: ${porcentajeminimo}`}>
        {data}
      </span>
    ) : (
      <span>{data}</span>
    )
  }
  const compResumen = (data: any) => {
    changePayload60(memoParams)
    return data?.row?.urlformato ? (
      <a target="blank" onClick={() => rowClick(data?.row?.idauditoria)}>
        Ver resumen
      </a>
    ) : (
      <></>
    )
  }

  const rowClick = (idauditoria: number): void => {
    changePayload60(memoParams)
    const url = '/detalle/submodulo/60/' + idauditoria
    history.push(url)
  }

  const headerColumns = [
    {
      key: 'fecha',
      name: 'FECHA',
      width: '5%',
      minWidth: 50,
      cellClass: () => {
        return 'colcenter'
      },
    },
    { key: 'descripcion', name: 'ZONA', width: '7%', minWidth: 50 },
    { key: 'tienda', name: 'TIENDA', width: '10%', minWidth: 50 },
    { key: 'periodo', name: 'PERIODO', width: '5%', minWidth: 50 },
    // { key: 'auditor', name: 'AUDITOR', width: 150 },
    // { key: 'jefetienda', name: 'JEFE TIENDA', width: 150 },
    {
      key: 'resultado',
      name: 'RESULTADO',
      width: '7%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.resultado, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'colcenter'
      },
    },
    // { key: 'situacion', name: 'SITUACIÓN', width: 150 },
  ]
  const headerColumnsAut316 = [
    {
      key: 'recaudacion',
      name: '% RECAUDACIÓN',
      width: '7%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.recaudacion, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut316'
      },
    },
    {
      key: 'contabilidad',
      name: '% CONTABILIDAD',
      width: '7%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.contabilidad, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut316'
      },
    },
    {
      key: 'folios',
      name: '% FOLIOS',
      width: '7%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.folios, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut316'
      },
    },
    {
      key: 'recursoshumanos',
      name: '% RRHH',
      width: '7%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.recursoshumanos, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut316'
      },
    },
    {
      key: 'operaciones',
      name: '% OPERACIONES',
      width: '7%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.operaciones, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut316'
      },
    },
    {
      key: 'tecnologia',
      name: '% TECNOLOGÍA',
      width: '7%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.tecnologia, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut316'
      },
    },
    {
      key: 'mantenimiento',
      name: '% MANTENIMIENTO',
      width: '7%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.mantenimiento, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut316'
      },
    },
    {
      key: 'sst',
      name: '% SST',
      width: '7%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.sst, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut316'
      },
    },
  ]
  const headerColumnsAut317 = [
    {
      key: 'recepcioncamion',
      name: '% RECEPCIÓN CAMIÓN',
      width: '9,3%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.recepcioncamion, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut317'
      },
    },
    {
      key: 'gestionalmacen',
      name: '% GESTIÓN ALMACÉN',
      width: '9,3%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.gestionalmacen, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut317'
      },
    },
    {
      key: 'gestionmerma',
      name: '% GESTIÓN MERMA',
      width: '9,3%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.gestionmerma, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut317'
      },
    },
    {
      key: 'gestionsegunda',
      name: '% GESTIÓN SEGUNDA',
      width: '9,3%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.gestionsegunda, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut317'
      },
    },
    {
      key: 'controlexistencia',
      name: '% CONTROL EXISTENCIA',
      width: '9,3%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.controlexistencia, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut317'
      },
    },
    {
      key: 'controldocumentario',
      name: '% CONTROL DOCUMENTARIO',
      width: '9,3%',
      minWidth: 50,
      formatter: (props: any) => compPercentage(props?.row?.controldocumentario, props?.row?.porcentajeminimo),
      cellClass: () => {
        return 'aut317'
      },
    },
  ]
  const headerColumnsLast = [
    // { key: 'flagfinalizado', name: 'FINALIZADO', width: 150},
    {
      key: 'urlformato',
      name: 'VER INFORME',
      width: '5%',
      minWidth: 50,
      formatter: compPDF,
      cellClass: () => {
        return 'colcenter'
      },
    },
    {
      key: 'idautoria',
      name: 'RESUMEN',
      width: '5%',
      minWidth: 50,
      formatter: compResumen,
      cellClass: () => {
        return 'colcenter'
      },
    },
  ]
  const [msgError, setMsgError] = useState<string>('')
  const [useMe, setUseMe] = useUserMe()
  const [stores, setStores] = useState<Array<ITiendas> | null>(null)
  const [checklists, setChecklists] = useState([])
  const [status, setStatus] = useState([])
  const [lists, setLists] = useState({ value: 316, label: 'Auditoria Operativa' })
  const [stateAttention, setStateAttention] = useState(defaultOption)
  const [store, setStore] = useState(defaultOption)
  const [rows, setRows] = useState<Array<any>>([])
  const [columns, setColumns] = useState(headerColumns)
  const [parameters, setParameters] = useState<IParameters>({})
  const [totalPerPage, setTotalPerPage] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [totalPages, setTotalPages] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const selectStyles = ESelectCustom
  const selectTheme = ESelectTheme
  const [endDate, setEndDate] = useState(new Date())
  const subsDays = dayjs(new Date()).subtract(7, 'day')
  const [startDate, setStartDate] = useState(subsDays.toDate())
  const [fullName, setFullName] = useState('')
  const btnSearchRef = useRef<any>(null)

  const getItems = (items: any, hasDefault?: boolean): any => {
    let temp: any = hasDefault ? [] : [defaultOption]

    items?.forEach((s: any) => {
      temp.push({
        value: s.id,
        label: s.descripcion,
      })
    })

    return temp
  }

  const getTypes = () => {
    HttpAxios.post('/entidad/estados', {
      id: 33,
      idestadopadre: 0,
      todos: false,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setChecklists(options)
        if (payload60) {
          const elem = options.filter((el: any) => el.value === payload60?.idcuadratura)
          if (elem) {
            onChangeChecklists(elem[0])
          }
          searchByBack()
        }
        changePayload60(null)
      })
      .catch((err) => {
        changePayload60(null)
      })
  }

  const getStatus = () => {
    HttpAxios.post('/entidad/estados', {
      id: 19,
      idestadopadre: 0,
      todos: true,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setStatus(options)
        if (payload60) {
          const elem = options.filter((el: any) => el.value === payload60?.idestado)
          if (elem) {
            onChangeStateAtention(elem[0])
          }
        }
        getTypes()
      })
      .catch((err) => {
        getTypes()
      })
  }

  const handleExport = () => {
    changeIsLoading(true)

    HttpAxios.post('/auditoria/listar_web_xls', parameters)
      .then((res: any) => {
        const { err, url, response } = res?.data || {}
        changeIsLoading(false)

        if (err) {
          changeSnackBar(EErrorMsg.generic)
        } else {
          handleDownload(url || response)
        }
      })
      .catch(() => {
        changeIsLoading(false)
        changeSnackBar(EErrorMsg.generic)
      })
  }

  const handleDownload = (urlFile: string = '') => {
    const link = document.createElement('a')
    link.href = urlFile
    link.setAttribute('download', `${urlFile.split('/')[urlFile.split('/').length - 1]}`)
    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      link?.parentNode?.removeChild(link)
    }, 100)
  }

  const search = (page: number = payload60?.page || 1, isPaginated?: boolean) => {
    if (!isPaginated) {
      const rangeStart = startDate ? dayjs(startDate).format(environment.search.formatDate) : ''
      const rangeEnd = endDate ? dayjs('' + endDate).format(environment.search.formatDate) : ''

      params = {
        idtienda: store?.value || 0,
        idtipoauditoria: lists?.value || 316,
        fecha_ini: rangeStart,
        fecha_fin: rangeEnd || rangeStart,
        responsable: fullName || '',
        limit: environment.search.limitPerPage,
        page: 1,
      }
      memoParams = params
    }

    let paramsSearch = params
    paramsSearch.page = page
    memoParams.page = page
    changeIsLoading(true)
    setInitLoading(true)
    setMsgError('')
    setRows([])

    HttpAxios.post('/auditoria/listar_web', paramsSearch)
      .then((res: any) => {
        const data = res?.data || {}
        const response = data.response || []
        if (response.length) {
          const { nextpage = 2, page = 1, total_pages = 0, total_rows = 0 } = data
          const conditionalCol = [...(paramsSearch.idtipoauditoria === 316 ? headerColumnsAut316 : headerColumnsAut317)]
          const customCol = [...headerColumns, ...conditionalCol, ...headerColumnsLast]
          setNoData(false)
          setColumns(customCol)
          setRows(response)
          setTotalPerPage(total_rows || response.length)
          setActivePage(page)
          setTotalPages(total_pages)
          setNextPage(nextpage)
          setParameters(memoParams)
          setMsgError('')
        } else {
          setNoData(true)
          setRows([])
        }

        changeIsLoading(false)
        setInitLoading(false)
      })
      .catch(() => {
        setMsgError(Errors.generic)
        changeIsLoading(false)
        setInitLoading(false)
      })
  }

  const handlePaged = (ind: number) => {
    search(ind, true)
  }

  const onChangeChecklists = (evt: any) => {
    setLists(evt)
  }

  const onChangeFullName = (evt: any) => {
    setFullName(evt.target.value)
  }

  const onChangeStateAtention = (evt: any) => {
    setStateAttention(evt)
  }

  const onChangeStores = (evt: any) => {
    setStore(evt)
  }

  const onChangeRange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const initBackValues = (stores: Array<ITiendas> | null) => {
    if (payload60) {
      const { fecha_ini, fecha_fin } = payload60
      const y: number = Number(fecha_ini.substring(0, 4))
      const m: number = Number(fecha_ini.substring(4, 6)) - 1
      const d: number = Number(fecha_ini.substring(6, 8))
      const yEnd: number = Number(fecha_fin.substring(0, 4))
      const mEnd: number = Number(fecha_fin.substring(4, 6)) - 1
      const dEnd: number = Number(fecha_fin.substring(6, 8))

      setStartDate(new Date(y, m, d))
      setEndDate(new Date(yEnd, mEnd, dEnd))

      const elem: any = (stores || []).filter((el: any) => el.id === payload60?.idtienda)
      if (elem) {
        const el = getItems(elem)
        onChangeStores(el.length === 1 ? el[0] : el[1])
      }

      setFullName(payload60?.supervisor! || '')
    }
  }

  const searchByBack = () => {
    if (payload60 && lists && btnSearchRef) {
      btnSearchRef.current.click()
    }
  }

  useEffect(() => {
    const stores: Array<ITiendas> | null = useMe?.tiendas || null
    setStores(stores)
    initBackValues(stores)
    getStatus()
    !payload60 && search()
    changeUrlBackTop('/submodulo/60')
    changeIsLoading(true)
  }, [])

  return (
    <>
      <div className="View60Search">
        <div className="row-6">
          <div className="col">
            <label className="label">Tienda</label>
            <Select id="store" name="store" styles={selectStyles} theme={selectTheme} value={store} onChange={(evt) => onChangeStores(evt)} placeholder="Selecciona una tienda" options={getItems(stores)} />
          </div>
          <div className="col">
            <label className="label">Tipo Auditoría</label>
            <Select id="lists" name="lists" styles={selectStyles} theme={selectTheme} value={lists} onChange={(evt) => onChangeChecklists(evt)} placeholder="Tipo Auditoría" options={checklists} />
          </div>
          <div className="col">
            <label className="label">Rango de fechas</label>
            <DatePicker
              selected={startDate}
              onChange={onChangeRange}
              startDate={startDate}
              endDate={endDate}
              selectsRange={true}
              locale="es"
              className="input"
              dateFormat="dd/MM/yyyy"
              placeholderText="Selecione un rango de fecha"
              maxDate={new Date()}
            />
          </div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button type="button" onClick={() => search()} className="button buttonFull" ref={btnSearchRef}>
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div className="View60Body">
        {noData || msgError ? (
          !initLoading && (
            <div
              className={classnames({
                noData: noData,
                msgError: msgError,
              })}
            >
              {msgError ? msgError : 'No se encontró resultado en la búsqueda'}
            </div>
          )
        ) : (
          <>
            <div className="View60SearchActions">
              <div className="View60SearchActionsTotal">{totalPerPage > 0 && 'Total de registros: ' + totalPerPage}</div>
              <div className="separate"></div>
              {totalPerPage > 0 && (
                <button className="button buttonExcel" type="button" onClick={handleExport}>
                  <img src={IconExcel} alt="Excel" className="buttonIconLeft" />
                  Exportar
                </button>
              )}
            </div>
            <ReactDataGrid
              className="View60Table"
              columns={columns}
              rows={rows}
              onRowsChange={setRows}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
            />
            <TablePaged msgError={msgError} activePage={activePage} handlePaged={handlePaged} totalPages={totalPages} />
          </>
        )}
      </div>
    </>
  )
}

export default View60
