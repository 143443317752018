export const EEmail = {
  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  minLength: 3,
  maxLength: 200,
}

export const EUser = {
  pattern: /^[a-zA-Z0-9]+$/i,
  minLength: 3,
  maxLength: 200,
}

export const EName = {
  minLength: 3,
  maxLength: 200,
}

export const ELastName = {
  minLength: 3,
  maxLength: 200,
}

export const EPassword = {
  minLength: 6,
  maxLength: 200,
}

export const EPosition = {
  minLength: 3,
  maxLength: 200,
}

export const EPhone = {
  pattern: /^\d+$/,
  minLength: 7,
  maxLength: 9,
}

export const EErrorMsg = {
  generic: 'Error: Vuelva a intentarlo. Ocurrio un error inesperado.',
  confirmPassword: 'Error: Las contraseñas ingresadas deben ser iguales.',
  dataForm: 'Error: Verifique los datos ingresados.',
  cuadratura: 'Error: No se ha encontrado una cuadratura válida para el usuario registrado'
}

export const ESuccessMsg = {
  saveObs: 'Se guardo la observación correctamente.',
  saveContrato: 'Se guardo el contrato correctamente.',
}

export const ESelectCustom = {
  control: (provided: any, { isDisabled }: any) => ({
    ...provided,
    height: 50,
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: isDisabled ? '#f7f4f2' : '#fff',
    borderColor: isDisabled ? '#b7b7b7' : '#b7b7b7',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 18px',
  }),
  dropdownIndicator: (provided: any, { isDisabled }: any) => ({
    ...provided,
    color: isDisabled ? '#ADADAD' : '#d50032',
  }),
  singleValue: (provided: any, { isDisabled }: any) => ({
    ...provided,
    color: isDisabled ? '#515151' : '#515151',
  }),
}

export const ESelectTheme = (theme: any) => ({
  ...theme,
  backgroundColor: 'blue',
  borderRadius: 3,
  colors: {
    ...theme.colors,
    primary: '#8b8b8b',
    danger: '#ea4335',
    primary75: '#f7f4f2',
    primary50: '#f7f4f2',
    primary25: '#f7f4f2',
    neutral80: '#8b8b8b',
  },
})

export const ESelectCustomPrimary = {
  control: (provided: any) => ({
    ...provided,
    height: 50,
    boxShadow: 'none',
    cursor: 'pointer',
    backgroundColor: '#8b8b8b',
    color: 'white',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 18px',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'white',
  }),
}

export const ESelectThemePrimary = (theme: any) => ({
  ...theme,
  borderRadius: 3,
  colors: {
    ...theme.colors,
    primary: '#4959b1',
    danger: '#ea4335',
    primary75: '#f5f5fa',
    primary50: '#f5f5fa',
    primary25: '#f5f5fa',
    neutral80: '#515151',
  },
})
