import { useContext, useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactDataGrid from 'react-data-grid'
import classnames from 'classnames'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import HttpAxios from '../../../commons/hooks/http'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import TableActions from '../../../commons/components/table/TableActions'
import TablePaged from '../../../commons/components/table/TablePaged'
import { useUserMe } from '../../../commons/hooks/userme'
import { AppContext } from '../../../commons/context/App-context'
import { ESelectCustom, ESelectTheme } from '../../../commons/statics/controls'
import { ITiendas } from '../../../commons/interfaces/user'
import environment from '../../../environments/environment'
import { Errors } from '../../../commons/constants/submodules'
import { IParameters } from '../../../commons/interfaces/items'
import { currencyFormat } from '../../../commons/utils/utils'
import es from 'date-fns/locale/es'
import './view58.scss'

registerLocale('es', es)

const defaultOption = {
  value: 0,
  label: 'TODOS',
}
let params: IParameters = {}
let memoParams: IParameters = {}

function View58() {
  const { changeIsLoading, changeUrlBackTop, changePayload58, payload58 } = useContext(AppContext)
  const history = useHistory()
  const [noData, setNoData] = useState<boolean>(false)
  const compCurrencyIV = (data: any) => {
    return <>{data?.row?.totalventa || data?.row?.totalventa === 0 ? currencyFormat(data.row.totalventa) : ''}</>
  }
  const compCurrencyD = (data: any) => {
    return <>{data?.row?.montodevolver || data?.row?.montodevolver === 0 ? currencyFormat(data.row.montodevolver) : ''}</>
  }
  const headerColumns = [
    {
      key: 'tienda',
      name: 'TIENDA',
      frozen: true,
      resizable: true,
      width: 170,
    },
    { key: 'codigoextorno', name: '# SOLICITUD EXTORNO', width: 180 },
    { key: 'fecharegistro', name: 'FECHA REGISTRO', width: 150 },
    { key: 'fechacomprobante', name: 'FECHA COMPROBANTE', width: 180 },
    { key: 'contacto', name: 'CLIENTE', width: 250 },
    { key: 'numerovoucher', name: '# VOUCHER', width: 150 },
    { key: 'codigoap', name: 'AP VOUCHER', width: 150 },
    { key: 'tipotarjeta', name: 'TIPO TARJETA', width: 150 },
    { key: 'numerotarjeta', name: '# TARJETA', width: 150 },
    { key: 'moneda', name: '# MONEDA', width: 160 },
    {
      key: 'totalventa',
      name: 'IMPORTE VENTA',
      width: 150,
      formatter: compCurrencyIV,
    },
    {
      key: 'montodevolver',
      name: 'DEVOLUCIÓN',
      width: 150,
      formatter: compCurrencyD,
    },
    { key: 'numeroatencion', name: '# TICKET', width: 150 },
    { key: 'estado', name: 'ESTADO', width: 140 },
  ]
  const [msgError, setMsgError] = useState<string>('')
  const [useMe, setUseMe] = useUserMe()
  const [stores, setStores] = useState<Array<ITiendas> | null>(null)
  const [checklists, setChecklists] = useState([])
  const [status, setStatus] = useState([])
  const [lists, setLists] = useState(defaultOption)
  const [stateAttention, setStateAttention] = useState(defaultOption)
  const [store, setStore] = useState(defaultOption)
  const [rows, setRows] = useState<Array<any>>([])
  const [columns, setColumns] = useState(headerColumns)
  const [parameters, setParameters] = useState<IParameters>({})
  const [totalPerPage, setTotalPerPage] = useState(0)
  const [nextPage, setNextPage] = useState(2)
  const [totalPages, setTotalPages] = useState(0)
  const [activePage, setActivePage] = useState(1)
  const [initLoading, setInitLoading] = useState<boolean>(false)
  const selectStyles = ESelectCustom
  const selectTheme = ESelectTheme
  const [endDate, setEndDate] = useState(new Date())
  const subsDays = dayjs(new Date()).subtract(7, 'day')
  const [startDate, setStartDate] = useState(subsDays.toDate())
  const [fullName, setFullName] = useState('')
  const btnSearchRef = useRef<any>(null)

  const getItems = (items: any, hasDefault?: boolean): any => {
    let temp: any = hasDefault ? [] : [defaultOption]

    items?.forEach((s: any) => {
      temp.push({
        value: s.id,
        label: s.descripcion,
      })
    })

    return temp
  }

  const getTypes = () => {
    HttpAxios.post('/entidad/estados', {
      id: 24,
      idestadopadre: 0,
      todos: true,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setChecklists(options)
        if (payload58) {
          const elem = options.filter((el: any) => el.value === payload58?.idoperadortarjeta)
          if (elem) {
            onChangeChecklists(elem[0])
          }
          searchByBack()
        }
        changePayload58(null)
      })
      .catch((err) => {
        changePayload58(null)
      })
  }

  const getStatus = () => {
    HttpAxios.post('/entidad/estados', {
      id: 23,
      idestadopadre: 0,
      todos: true,
    })
      .then((res: any) => {
        const items: Array<any> = res?.data?.response || []
        const options = getItems(items, true)
        setStatus(options)
        if (payload58) {
          const elem = options.filter((el: any) => el.value === payload58?.idestado)
          if (elem) {
            onChangeStateAtention(elem[0])
          }
        }
        getTypes()
      })
      .catch((err) => {
        getTypes()
      })
  }

  const search = (page: number = payload58?.page || 1, isPaginated?: boolean) => {
    if (!isPaginated) {
      const rangeStart = startDate ? dayjs(startDate).format(environment.search.formatDate) : ''
      const rangeEnd = endDate ? dayjs('' + endDate).format(environment.search.formatDate) : ''

      params = {
        idtienda: store?.value || 0,
        idoperadortarjeta: lists?.value || 0,
        fecha_ini: rangeStart,
        fecha_fin: rangeEnd || rangeStart,
        idestado: stateAttention?.value || 0,
        cliente: fullName || '',
        limit: environment.search.limitPerPage,
      }
      memoParams = params
    }

    let paramsSearch = params
    paramsSearch.page = page
    memoParams.page = page
    changeIsLoading(true)
    setInitLoading(true)
    setMsgError('')
    setRows([])

    HttpAxios.post('/extorno/listar_web', paramsSearch)
      .then((res: any) => {
        const data = res?.data || {}
        const response = data.response || []
        if (response.length) {
          const { nextpage = 2, page = 1, total_pages = 0, total_rows = 0 } = data
          setNoData(false)
          setRows(response)
          setTotalPerPage(total_rows || response.length)
          setActivePage(page)
          setTotalPages(total_pages)
          setNextPage(nextpage)
          setParameters(memoParams)
          setMsgError('')
        } else {
          setNoData(true)
          setRows([])
        }

        changeIsLoading(false)
        setInitLoading(false)
      })
      .catch(() => {
        setMsgError(Errors.generic)
        changeIsLoading(false)
        setInitLoading(false)
      })
  }

  const handlePaged = (ind: number) => {
    search(ind, true)
  }

  const onChangeChecklists = (evt: any) => {
    setLists(evt)
  }

  const onChangeFullName = (evt: any) => {
    setFullName(evt.target.value)
  }

  const onChangeStateAtention = (evt: any) => {
    setStateAttention(evt)
  }

  const onChangeStores = (evt: any) => {
    setStore(evt)
  }

  const onChangeRange = (dates: any) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const rowClick = (obj: any, r: any): void => {
    changePayload58(memoParams)
    const url = '/detalle/submodulo/58/' + obj?.idextorno
    history.push(url)
  }

  const initBackValues = (stores: Array<ITiendas> | null) => {
    if (payload58) {
      const { fecha_ini, fecha_fin } = payload58
      const y: number = Number(fecha_ini.substring(0, 4))
      const m: number = Number(fecha_ini.substring(4, 6)) - 1
      const d: number = Number(fecha_ini.substring(6, 8))
      const yEnd: number = Number(fecha_fin.substring(0, 4))
      const mEnd: number = Number(fecha_fin.substring(4, 6)) - 1
      const dEnd: number = Number(fecha_fin.substring(6, 8))

      setStartDate(new Date(y, m, d))
      setEndDate(new Date(yEnd, mEnd, dEnd))

      const elem: any = (stores || []).filter((el: any) => el.id === payload58?.idtienda)
      if (elem) {
        const el = getItems(elem)
        onChangeStores(el.length === 1 ? el[0] : el[1])
      }

      setFullName(payload58?.cliente! || '')
    }
  }

  const searchByBack = () => {
    if (payload58 && lists && btnSearchRef) {
      btnSearchRef.current.click()
    }
  }

  useEffect(() => {
    const stores: Array<ITiendas> | null = useMe?.tiendas || null
    setStores(stores)
    initBackValues(stores)
    getStatus()
    !payload58 && search()
    changeUrlBackTop('/submodulo/58')
    changeIsLoading(true)
  }, [])

  return (
    <>
      <div className="View58Search">
        <div className="row-6">
          <div className="col">
            <label className="label">Tienda</label>
            <Select id="store" name="store" styles={selectStyles} theme={selectTheme} value={store} onChange={(evt) => onChangeStores(evt)} placeholder="Selecciona una tienda" options={getItems(stores)} />
          </div>
          <div className="col">
            <label className="label">Operador Tarjeta</label>
            <Select id="lists" name="lists" styles={selectStyles} theme={selectTheme} value={lists} onChange={(evt) => onChangeChecklists(evt)} placeholder="Selecciona un operador" options={checklists} />
          </div>
          <div className="col">
            <label className="label">Estado Ticket</label>
            <Select id="stateAttention" name="stateAttention" styles={selectStyles} theme={selectTheme} value={stateAttention} onChange={(evt) => onChangeStateAtention(evt)} placeholder="Selecciona un estado" options={status} />
          </div>
          <div className="col">
            <label className="label">Rango de fechas</label>
            <DatePicker
              selected={startDate}
              onChange={onChangeRange}
              startDate={startDate}
              endDate={endDate}
              selectsRange={true}
              locale="es"
              className="input"
              dateFormat="dd/MM/yyyy"
              placeholderText="Selecione un rango de fecha"
              maxDate={new Date()}
            />
          </div>
          <div className="col">
            <label className="label">Cliente</label>
            <input className="input" id="fullName" name="fullName" placeholder="Cliente" value={fullName} onChange={(evt) => onChangeFullName(evt)} />
          </div>
          <div className="col">
            <label className="label empty">&nbsp;</label>
            <button type="button" onClick={() => search()} className="button buttonFull" ref={btnSearchRef}>
              Buscar
            </button>
          </div>
        </div>
      </div>

      <div className="View58Body">
        {noData || msgError ? (
          !initLoading && (
            <div
              className={classnames({
                noData: noData,
                msgError: msgError,
              })}
            >
              {msgError ? msgError : 'No se encontró resultado en la búsqueda'}
            </div>
          )
        ) : (
          <>
            <TableActions totalPerPage={totalPerPage} paramsExport={parameters} urlExport={'/extorno/listar_web_xls'} />
            <ReactDataGrid
              className="View58Table"
              columns={columns}
              rows={rows}
              onRowsChange={setRows}
              onRowClick={rowClick}
              defaultColumnOptions={{
                sortable: true,
                resizable: true,
              }}
            />
            <TablePaged msgError={msgError} activePage={activePage} handlePaged={handlePaged} totalPages={totalPages} />
          </>
        )}
      </div>
    </>
  )
}

export default View58
