import './spinner.scss'

function Spinner() {
  return (
    <div className="Spinner">
      <div className="SpinnerInner">
        <span className="spinner"></span>
      </div>
    </div>
  )
}

export default Spinner
