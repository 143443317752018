import { useContext, useState, useCallback, useEffect } from 'react'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useForm } from 'react-hook-form'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import environment from '../../../environments/environment'
import { useUserMe } from '../../../commons/hooks/userme'
import { EUser, EPassword, EErrorMsg } from '../../../commons/statics/controls'
import { AppContext } from '../../../commons/context/App-context'
import Footer from '../../../commons/components/footer/Footer'
import './login.scss'

interface ILogin {
  user?: string
  password?: string
}

const Login = () => {
  const history = useHistory()
  const { isLoading, changeIsLoading } = useContext(AppContext)
  const [msgError, setMsgError] = useState('')
  const { register, handleSubmit, formState } = useForm({
    mode: 'onChange',
  })
  const { errors, isDirty, isValid } = formState
  const [userMe, setUserMe] = useUserMe()
  const [token, setToken] = useState<string>('')

  const handleOnSubmit = (u: ILogin) => {
    const { user = '', password = '' } = u || {}
    changeIsLoading(true)
    setMsgError('')

    if (user && password) {
      axios
        .post(environment.apiURL + '/login', {
          username: user,
          password,
          plataforma: 'web',
        })
        .then((res: any) => {
          const user = res?.data || {}
          const { err } = user
          changeIsLoading(false)
          if (err) {
            setMsgError(err)
          } else {
            ;(async () => {
              await setUserMe({
                ...user,
              })
            })()

            const redirectUrl = localStorage.getItem('redirectAfterLogin') || '/'
            localStorage.removeItem('redirectAfterLogin')

            history.push(redirectUrl)
          }
        })
        .catch(() => {
          changeIsLoading(false)
          setMsgError(EErrorMsg.generic)
          setUserMe.reset()
        })
    } else {
      changeIsLoading(false)
      setMsgError(EErrorMsg.dataForm)
    }
  }

  const handleReCaptchaVerify = (u: ILogin) => {
    if (token && u) {
      handleOnSubmit(u)
    } else {
      console.error('Captcha Inválido')
    }
  }

  const handleVerify = useCallback((t: string) => {
    setToken(t)
  }, [])

  return (
    <>
      {/* <GoogleReCaptchaProvider
                language="es"
                reCaptchaKey={environment.reCaptcha}
            > */}
      <div className="LoginWrapFull">
        <div className="LoginBanner">
          <img src="/images/login-left.svg" alt="" />
          <img className="LoginBannerLeft" src="/images/login-left-hand.svg" alt="" />
          <img className="LoginBannerRight" src="/images/login-left-bulb.svg" alt="" />
        </div>
        <div className="LoginWrap">
          <form onSubmit={handleSubmit(environment.isCaptcha ? handleReCaptchaVerify : handleOnSubmit)} autoComplete="off" className="LoginWrapInner">
            <div className="LoginWrapLogo">
              <img alt="CasaIdeas" src="/images/logo.svg" />
              <p>Control Operativo Perú</p>
            </div>
            <div className="LoginWrapForm">
              <div className="mbm">
                <label
                  className={classnames({
                    label: true,
                    invalid: errors?.user,
                  })}
                  htmlFor="user"
                >
                  Usuario
                </label>
                <input
                  defaultValue=""
                  className={classnames({
                    input: true,
                    invalid: errors?.user,
                  })}
                  id="user"
                  type="text"
                  placeholder="Ingresa tu usuario"
                  autoComplete="off"
                  maxLength={EUser.maxLength}
                  {...register('user', {
                    required: {
                      value: true,
                      message: 'Usuario es requerido.',
                    },
                    pattern: {
                      value: EUser.pattern,
                      message: 'Ingresa un Usuario válido.',
                    },
                    minLength: {
                      value: EUser.minLength,
                      message: 'Min. ' + EUser.minLength + ' caracteres.',
                    },
                    maxLength: {
                      value: EUser.maxLength,
                      message: 'Max. ' + EUser.maxLength + ' caracteres.',
                    },
                  })}
                />
                <div className="helpText invalid">{errors?.user && errors?.user?.message}</div>
              </div>
              <div className="mb1">
                <label
                  className={classnames({
                    label: true,
                    invalid: errors.password,
                  })}
                  htmlFor="password"
                >
                  Contraseña
                </label>
                <input
                  defaultValue=""
                  className={classnames({
                    input: true,
                    invalid: errors.password,
                  })}
                  id="password"
                  type="password"
                  placeholder="Ingresa tu contraseña"
                  autoComplete="off"
                  maxLength={EPassword.maxLength}
                  {...register('password', {
                    required: {
                      value: true,
                      message: 'Contraseña es requerida.',
                    },
                    minLength: {
                      value: EPassword.minLength,
                      message: 'Min. ' + EPassword.minLength + ' caracteres.',
                    },
                    maxLength: {
                      value: EPassword.maxLength,
                      message: 'Max. ' + EPassword.maxLength + ' caracteres.',
                    },
                  })}
                />
                <div className="helpText invalid">{errors?.password && errors?.password?.message}</div>
              </div>
              <div className="mb1">
                <button className="button buttonFit" type="submit" disabled={isLoading || !isDirty || !isValid}>
                  Inicia sesión
                </button>
              </div>
              {msgError && <div className="mb1 helpText invalid">{msgError}</div>}
            </div>
          </form>
        </div>
      </div>
      <Footer></Footer>
      {/* <GoogleReCaptcha onVerify={handleVerify} />
            </GoogleReCaptchaProvider> */}
    </>
  )
}

export default Login
