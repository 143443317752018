import { useContext, useEffect, useState } from 'react'
import { useUserMe } from '../../commons/hooks/userme'
import { AppContext } from '../../commons/context/App-context'
import Header from '../../commons/components/header/Header'
import Breadcrumb from '../../commons/components/breadcrumb/Breadcrumb'
import Footer from '../../commons/components/footer/Footer'
import { EErrorMsg } from '../../commons/statics/controls'
import './dashboard.scss'

function Dashboard() {
  const { isLoading, changeIsLoading, changeSnackBar } = useContext(AppContext, )
  const [msgError, setMsgError] = useState<string>('')
  const [useMe, setUseMe] = useUserMe()
  const [initLoading, setInitLoading] = useState<boolean>(false)

  useEffect(() => {
    setInitLoading(false)
    changeIsLoading(false)
    setMsgError('')

    const cuadraturaMessage = localStorage.getItem('cuadraturaMessage');

    if (cuadraturaMessage === 'true') {
      changeSnackBar(EErrorMsg.cuadratura)
      localStorage.setItem('cuadraturaMessage', 'false');
    }
  }, [])

  return (
    <>
      <div className="ContentFull">
        <Header></Header>
        <div className="Content">
          <Breadcrumb text="Home"></Breadcrumb>
          <div className="ContentInner">
            <img src="/images/login-left.svg" alt="" />
          </div>
        </div>
        <Footer></Footer>
      </div>
    </>
  )
}

export default Dashboard
